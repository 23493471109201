import { dismissAction, toggleAction } from 'pages/notifcation/notificationSlice';
import { delay } from 'lodash';

export const useNotification = () => {
    const success = ({ message }: any) => {
        toggleAction({
            type: 'success',
            color: '#83C567',
            text: message,
        });

        delay(() => dismissAction(), 3000);
    };

    const error = ({ message }: any) => {
        toggleAction({
            type: 'error',
            color: 'red',
            text: message,
        });

        delay(() => dismissAction(), 3000);
    };
    const warning = ({ message }: any) => {
        toggleAction({
            type: 'warning',
            color: '#FFCC00',
            text: message,
        });
    };
    return { success, error, warning };
};
