import { Modal } from 'components';

import {
    Send,
    SendVedioFeedBack,
    SendModal,
    SendTitle,
    SendCardModal,
    HeaderModal2,
    HeaderTitle2,
    FeedBackAbout,
    ModuleName,
} from '../style';

export const SendVideoModal = ({
    sendVedioModal,
    setSendVidoeModal,
    setFeedback,
    feedback,
    SendVideo,
    loadingUpload,
    moduleName,
}: any) => {
    const TitleReturnModal = (
        <HeaderModal2>
            <HeaderTitle2>Job well done!</HeaderTitle2>
            <span>
                Please provide a summary about this feedback, for the next reviewer to catch up
            </span>
            <div>
                <FeedBackAbout>This feedback is about{'      '}</FeedBackAbout>
                <ModuleName>
                    {'     '} ({'  '} {moduleName} {'   '})
                </ModuleName>
            </div>
        </HeaderModal2>
    );
    return (
        <Modal
            isOpen={sendVedioModal}
            setIsOpen={setSendVidoeModal}
            title={TitleReturnModal}
            Style={{ top: '13%', borderRadius: '5px', padding: '30px' }}
            infoModal={true}
            flag={true}
        >
            <SendModal>
                <SendCardModal>
                    <SendTitle>
                        <SendVedioFeedBack
                            onChange={(e) => setFeedback(e.target.value)}
                            value={feedback}
                        />
                        <Send onClick={SendVideo} loading={loadingUpload}>
                            <span>Send video</span>
                        </Send>
                    </SendTitle>
                </SendCardModal>
            </SendModal>
        </Modal>
    );
};
