import { useSelector } from 'react-redux';
import { dismissAction } from './notificationSlice';
import { ReactComponent as NotifSuccessSVG } from 'assets/icons/notif-success.svg';
import { ReactComponent as InfoSVG } from 'assets/icons/Info.svg';
import { ReactComponent as CloseSVG } from 'assets/icons/_close.svg';
import { ReactComponent as WarningSVG } from 'assets/icons/Warning.svg';

import { capitalize } from 'lodash';
import styled from 'styled-components';

const IconButtonStyled = styled.button`
    background: transparent;
    border: 0;
`;

const NotifSuccessSVGStyled = styled(NotifSuccessSVG)`
    width: 16px;
    height: 16px;
    margin-inline-end: 4px;
    margin-top: 2px;
`;

const CloseSVGStyled = styled(CloseSVG)`
    width: 16px;
    height: 16px;
`;

const ToastrInfoSVGStyled = styled(InfoSVG)`
    width: 25px;
    height: 25px;
    margin-inline-end: 6px;
`;
const WarningSVGStyled = styled(WarningSVG)`
    width: 25px;
    height: 25px;
    margin-inline-end: 6px;
    /* filter: invert(76%) sepia(74%) saturate(1887%) hue-rotate(0deg) brightness(105%) contrast(104%);
    border: 1px solid black; */
`;
const TextStyled = styled.span`
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
    display: flex;
    flex: 1;
`;

export function Templates() {
    const { text, type }: any = useSelector<any>((state) => state?.notifcation) as any;

    switch (type) {
        case 'success':
            return (
                <>
                    <NotifSuccessSVGStyled />
                    <TextStyled className="my-auto text-white">{capitalize(text)}</TextStyled>
                    <IconButtonStyled onClick={dismissAction}>
                        <CloseSVGStyled />
                    </IconButtonStyled>
                </>
            );
        case 'error':
            return (
                <>
                    <ToastrInfoSVGStyled />
                    <TextStyled className="my-auto text-white">{capitalize(text)}</TextStyled>
                    <IconButtonStyled onClick={dismissAction}>
                        <CloseSVGStyled />
                    </IconButtonStyled>
                </>
            );
        case 'warning':
            return (
                <>
                    <WarningSVGStyled />
                    <TextStyled className="my-auto text-white text-truncate">
                        {capitalize(text)}
                    </TextStyled>
                    {/* <IconButtonStyled onClick={dismissAction}>
                        <CloseSVGStyled />
                    </IconButtonStyled> */}
                </>
            );
        default:
            return null;
    }
}
