import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from 'assets/image/vedio-logo.svg';
import { ReactComponent as Info } from 'assets/image/info.svg';
import { ReactComponent as Home } from 'assets/image/home.svg';
import { ReactComponent as InfoDisabled } from 'assets/image/info-disabled.svg';
import { ReactComponent as HomeDisabled } from 'assets/image/home-disabled.svg';
import router from 'routers/router';
import { Dvr } from 'pages/dvr';

import { Header } from '../../layout/components';
import { Button, Modal } from 'components';
import { useLocales } from 'hooks';

const VedioHeader = styled(Header)`
    background: #1b202e !important;
    background-color: #1b202e !important;
    max-width: 100% !important;
`;
const VedioBox = styled.div`
    display: flex;
    background-color: #1e2633;
    background: #1e2633;
    flex-direction: column;
    height: 100%;
    width: 100%;
    /* max-width: 1200px; */
    margin: 0 auto;
    overflow-y: auto;
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
        height: 50px;
        background: #1e2633 !important;
        padding: 5px;
        /* border: 1px solid black !important; */
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 100px;
        width: 6px;
        padding: 5px;
        background: #1e2633 !important;
        /* border: none !important; */
        /* border: 1px solid black !important; */
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #777f85;

        border-radius: 100px !important;
        width: 6px;
        border: 2px solid black !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #777f85;
        width: 6px;
        border: 2px solid black !important;
    }
    div::-webkit-scrollbar:horizontal {
        display: none;
    }
`;
const HeaderChildren = styled.div`
    margin: auto 0;
    display: flex;
    justify-content: space-evenly;
    /* margin-right: 1%; */

    margin-right: 20px;
`;

const InfoModalMody = styled.div`
    display: flex;
    justify-content: space-between;
    color: #c9eef8;
    max-width: 1080px;
    width: 100%;
    height: 100%;
`;

const HeaderModal = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: #c9eef8;
    width: 100%;
    margin-top: 6px;
    padding-bottom: 5px;
`;
const HeaderTitle = styled.h4`
    /* width: 48%; */
    font-weight: 400;
    font-size: 21px !important;
    line-height: 30px;
`;

const TitleReturnModal = (
    <HeaderModal>
        <HeaderTitle>Go back to Home page</HeaderTitle>
    </HeaderModal>
);

const CardModal = styled.div`
    background: #0b141d;
    border-radius: 10px;
    width: 100%;
    /* padding: 4%; */
    margin-right: 5px;
    display: flex;
    justify-content: space-around;
    /* margin-bottom: 2%; */
`;

const TitleDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    /* padding: 2%; */
    font-size: 35px;
`;
const TitleVlaue = styled.span`
    font-style: normal;
    font-weight: 300;
    line-height: 23px;
    font-weight: 400;
    color: #fafbfd;
    font-size: 25px;
`;
const ReturnText = styled(TitleVlaue)`
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 3%;
    font-weight: 300;
    line-height: 35px;
    font-size: 18px;
`;
const SureButton = styled(Button)`
    padding: 1%;
    background: transparent !important;
    color: #1db4df;
    border: 2px solid #1db4df;
    border-radius: 5px;
    width: max-content;
    line-height: 24px;

    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        font-size: 16px !important;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        font-size: 14px !important;

        /* width: 15%; */
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
        /* start of phone styles */
        /* width: 20%; */
        font-size: 12px !important;
    }
    @media screen and (min-width: 600px) and (max-width: 767px) {
        /* start of phone styles */
        /* width: 20%; */
        font-size: 11px !important;
    }
    margin: 0 auto;
    margin-top: 5%;
    margin-bottom: 5%;
    /* font-size: 15px !important; */
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const DVRCOntanier = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
const InfoImage = styled(Info)`
    background: transparent;
    border: none;
    cursor: pointer;
    /* padding-left: 9%; */
    margin-right: 20px;
`;
const HomeImage = styled(Home)`
    background: transparent;
    border: none;
    cursor: pointer;
    /* padding-left: 9%; */
`;

const InfoImageDisabled = styled(InfoDisabled)`
    background: transparent;
    border: none;
    cursor: not-allowed;
    margin-right: 22px;
`;
const HomeImageDisabled = styled(HomeDisabled)`
    background: transparent;
    border: none;
    cursor: not-allowed;
`;
const HomeImageDisabledContanier = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(19 26 38);
    /* border: 0.5px solid #0b141d; */
    border-radius: 16px;
    cursor: not-allowed;
`;
export const LeaveModal = styled.div`
    display: flex;
    justify-content: space-between;
    color: #c9eef8;

    width: 100%;
    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        max-width: 550px;
        min-width: 490px;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        max-width: 490px;
        min-width: 400px;
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
        /* start of phone styles */
        max-width: 400px;
        min-width: 200px;
    }
`;

export function VideoContainer() {
    const [showInfoModal, setInfoShowModal] = useState(false);
    const [returnHomeModal, setReturnHomeModal] = useState(false);
    const [isRecording, setisRecording] = useState(false);
    const { trans } = useLocales();
    useEffect(() => {
        document.body.style.background = '#1b202e';
    }, []);
    return (
        <VedioBox>
            <VedioHeader
                Logo={Logo}
                colorBackground="#1b202e"
                logoStyle={true}
                handleCloaseVideoPage={setReturnHomeModal}
                isRecording={isRecording}
            >
                <HeaderChildren>
                    {!isRecording ? (
                        <>
                            <InfoImage onClick={() => setInfoShowModal(true)} />
                            <HomeImage onClick={() => setReturnHomeModal(true)} />
                        </>
                    ) : (
                        <>
                            <InfoImageDisabled />
                            <HomeImageDisabledContanier>
                                <HomeImageDisabled />
                            </HomeImageDisabledContanier>
                        </>
                    )}
                </HeaderChildren>
            </VedioHeader>
            <DVRCOntanier>
                <Dvr
                    vedioId={router.getState().params.id}
                    setInfoShowModal={setInfoShowModal}
                    showInfoModal={showInfoModal}
                    returnHomeModal={returnHomeModal}
                    hideInfoIcon={setisRecording}
                />
            </DVRCOntanier>

            <Modal
                isOpen={returnHomeModal}
                setIsOpen={setReturnHomeModal}
                title={TitleReturnModal}
                Style={{ top: '19%', width: '550px', height: '331px', padding: '20px' }}
                flag={true}
            >
                <InfoModalMody>
                    <CardModal>
                        <TitleDetails>
                            <ReturnText>
                                {trans('dvr.leave')}
                                <br></br> {trans('dvr.discarded')}
                            </ReturnText>
                            <SureButton onClick={() => router.navigate('home')}>
                                I’m sure
                            </SureButton>
                        </TitleDetails>
                    </CardModal>
                </InfoModalMody>
            </Modal>
        </VedioBox>
    );
}
