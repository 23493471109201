interface audioContextProps {
    stream: any;
    videoStream: any;
}

export const audioContext = ({
    stream,
    videoStream,
}: audioContextProps): MediaStreamAudioDestinationNode => {
    if (!stream || !videoStream) return {} as MediaStreamAudioDestinationNode;
    const context = new AudioContext();
    const microphone = context?.createMediaStreamSource(stream);
    const analyser = context?.createAnalyser();
    const backgroundMusic = context?.createMediaStreamSource(videoStream);
    const mixedOutput = context?.createMediaStreamDestination();

    microphone.connect(analyser);
    analyser.connect(mixedOutput);
    backgroundMusic.connect(mixedOutput);

    return mixedOutput;
};
