import { Button } from 'components/button';
import styled, { css } from 'styled-components';

export const ControlsWrap = styled.div`
    display: flex;

    align-items: center;
    width: 14%;
    /* padding-right: 30px; */
    justify-content: space-between;
    /* margin-left: 2%; */
    /* margin-left: 33px; */
    margin-left: 30px;
    margin-right: 10px;
    @media screen and (min-width: 991px) and (max-width: 1200px) {
        padding: 1%;
        margin-right: 30px;
        margin-left: 30px;
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
        padding: 1%;
        margin-right: 30px;
        margin-left: 30px;
    }
    @media screen and (min-width: 600px) and (max-width: 767px) {
        padding: 1%;
        margin-right: 30px;
        margin-left: 30px;
    }
`;
export const DurationLabel = styled.span`
    color: rgba(250, 251, 253, 1);
    /* width: 159px; */
    width: 17%;
    /* padding-left: 30px; */
    font-weight: 300;
    /* margin-right: 30px; */
    padding: 0;
    @media screen and (max-width: 1200px) {
        /* start of large tablet styles */
        padding: 1%;
        margin-left: 22px;
    }
    @media screen and (min-width: 1200px) {
        /* start of large tablet styles */
        font-size: 24px;
        line-height: 24px;
    }
    @media screen and (min-width: 991px) and (max-width: 1200px) {
        /* start of medium tablet styles */
        font-size: 22px;
        line-height: 24px;
        padding: 1%;
        margin-left: 30px;
        /* width: 15%; */
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
        /* start of phone styles */
        /* width: 20%; */
        font-size: 15px;
        line-height: 24px;
        padding: 1%;
        margin-left: 30px;
    }
    @media screen and (min-width: 600px) and (max-width: 767px) {
        /* start of phone styles */
        /* width: 20%; */
        font-size: 13px;
        line-height: 24px;
        padding: 1%;
        margin-left: 30px;
    }
`;
export const Seek = styled.input`
    cursor: pointer;
    ${(props) => css`
        background: linear-gradient(
            to right,
            #f5bc3d 0%,
            #f5bc3d ${`${Number(props.value)}%`},
            #32383d ${`${Number(props.value)}%`},
            #32383d 100%
        );
    `}

    ::-webkit-slider-thumb {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        -webkit-appearance: none;
        cursor: ew-resize;
        background: #f5bc3d;
    }
    border: solid 1px #32383d;
    border-radius: 8px;
    height: 6px;
    /* width: 70%; */
    transition: all 450ms ease-in;
    -webkit-appearance: none;
    @media screen and (min-width: 1200px) {
        /* start of large tablet styles */
        width: 90%;
    }
    @media screen and (min-width: 991px) and (max-width: 1200px) {
        /* start of medium tablet styles */

        width: 80%;
    }
    @media screen and (min-width: 767px) and (max-width: 991px) {
        width: 80%;
    }
    @media screen and (max-width: 766px) {
        width: 70%;
    }
`;
export const Box = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: #1b202e;
    align-items: center;
    padding-top: 1vh;
    padding-bottom: 1vh;
    position: absolute;
    bottom: 0px;
    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        height: 57px;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        height: 47px;
    }
    @media screen and (min-width: 767px) and (max-width: 991px) {
        height: 37px;
    }
    @media screen and (max-width: 766px) {
        height: 35px;
    }
`;

export const BackwordButton = styled(Button)`
    background-color: transparent !important;
    border: none !important;
    position: relative;
    > span {
        margin: 0;
        @media screen and (min-width: 1199px) {
            /* start of large tablet styles */
            font-size: 23px;
        }
        @media screen and (min-width: 991px) and (max-width: 1199px) {
            /* start of medium tablet styles */
            font-size: 18px;
        }
        @media screen and (min-width: 767px) and (max-width: 991px) {
            font-size: 13px;
        }
        @media screen and (max-width: 766px) {
            font-size: 8px;
        }
    }
`;
