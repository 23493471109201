import React from 'react';
import { RouterProvider } from 'react-router5';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { msalConfig } from './config/azureAuthConfig';
import {
    AuthenticationResult,
    EventMessage,
    EventType,
    PublicClientApplication,
} from '@azure/msal-browser';
import { PersistGate } from 'redux-persist/es/integration/react';
import App from 'app/App';
import { router } from 'routers';
import { store, persistor } from 'app/store';
import reportWebVitals from './reportWebVitals';
import { I18nProvider } from 'locales';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'theme/theme.scss';

export const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <RouterProvider router={router}>
                    <I18nProvider>
                        <App instance={msalInstance} />
                    </I18nProvider>
                </RouterProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
);

reportWebVitals();
