import { Modal } from 'components';
import styled from 'styled-components';

import {
    BoadyDeleteModal,
    DeleteCardModal,
    TitleDeleteDetails,
    ReturnTextDelete,
    SureButton,
} from '../style';

const HeaderTitle = styled.h4`
    font-weight: 400;
    font-size: 21px !important;
    line-height: 30px;
`;
const HeaderModal = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: #c9eef8;
    width: 100%;
    margin-top: 6px;
    padding-bottom: 5px;
`;
export const ReturnToAdminModal = ({
    handelToVideoAdmin,
    returnToAdminLoading,
    returnToAdminModal,
    setReturnToAdminModal,
}: any) => {
    const TitleReturnToAdminModal = (
        <HeaderModal>
            <HeaderTitle>Return Video To Admin </HeaderTitle>
        </HeaderModal>
    );
    return (
        <Modal
            isOpen={returnToAdminModal}
            setIsOpen={setReturnToAdminModal}
            title={TitleReturnToAdminModal}
            Style={{ top: '19%', width: '550px', height: '331px', padding: '20px' }}
            flag={true}
        >
            <BoadyDeleteModal>
                <DeleteCardModal>
                    <TitleDeleteDetails>
                        <ReturnTextDelete>
                            Are you sure you want to return to the admin ?{' '}
                        </ReturnTextDelete>
                        <SureButton onClick={handelToVideoAdmin} loading={returnToAdminLoading}>
                            I’m sure
                        </SureButton>
                    </TitleDeleteDetails>
                </DeleteCardModal>
            </BoadyDeleteModal>
        </Modal>
    );
};
