import { Loader as DefaultLoader } from 'components';
import styled from 'styled-components';

const Box = styled.div`
    text-align: center;
    overflow: hidden;
`;

export const Loader = () => {
    return (
        <Box>
            <DefaultLoader />
        </Box>
    );
};
