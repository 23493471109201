import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';

interface ButtonProps {
    children?: React.ReactNode;
    loading?: Boolean;
    disabled?: Boolean | any;
}

const Box = styled.button<ButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: $border-radius-regular;
    transition-property: background-color, color, box-shadow;
    transition-delay: 0s;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid #1db4df !important;
    background: #1db4df !important;
    color: #fff;
    font-size: 24px !important;
    > span {
        color: #fafbfd;
        font-weight: 200;
        font-size: 24px;
        margin-top: 3px;
    }
    &:disabled {
        border: none !important;
        background: #bbe9f5 !important;
        cursor: not-allowed;
    }
`;

export const Button = React.memo(
    ({
        children,
        loading,
        disabled,
        ...props
    }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
        <Box {...props} data-cmp="Button" disabled={disabled}>
            {loading ? <ClipLoader color="#fff" size={15} /> : children}
        </Box>
    ),
);
