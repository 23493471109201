import { Button, Loader } from 'components';
import styled, { css } from 'styled-components';

const Box = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 1%;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    @media screen and (max-width: 1199px) {
        /* start of large tablet styles */
        margin-right: 30px;
        margin-left: 30px;
    }
`;
const ReturnToAdmin = styled.span`
    display: flex;
    align-items: center;
    color: #1db4df;
    text-decoration-line: underline;
    text-align: end;
    cursor: pointer;
    /* margin-left: 18px; */

    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        font-size: 20px;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        font-size: 18px;
    }

    @media screen and (min-width: 700px) and (max-width: 991px) {
        /* start of phone styles */
        font-size: 15px;
    }
    /* display: flex; */
`;
const Send = styled(Button)`
    padding: 1vh;
    background: transparent !important;
    border-radius: 5px;
    /* margin-right: 15px; */
    > span:nth-child(1) {
        color: #1db4df !important;
        font-weight: 400;
        line-height: 24px;
        @media screen and (min-width: 1199px) {
            /* start of large tablet styles */
            font-size: 16px !important;
        }
        @media screen and (min-width: 991px) and (max-width: 1199px) {
            /* start of medium tablet styles */
            font-size: 14px !important;

            /* width: 15%; */
        }

        @media screen and (min-width: 767px) and (max-width: 991px) {
            /* start of phone styles */
            /* width: 20%; */
            font-size: 12px !important;
        }
        @media screen and (min-width: 600px) and (max-width: 767px) {
            /* start of phone styles */
            /* width: 20%; */
            font-size: 11px !important;
        }
    }

    ${(props) =>
        props.disabled
            ? css`
                  &:disabled {
                      color: rgba(29, 180, 223, 0.2) !important;
                      border: 2px solid rgba(29, 180, 223, 0.2) !important;
                      background: transparent !important;

                      cursor: not-allowed;
                      > span:nth-child(1) {
                          color: rgba(29, 180, 223, 0.2) !important;
                      }
                  }
              `
            : css`
                  color: #1db4df !important;
                  border: 2px solid #1db4df !important;
                  > span:nth-child(1) {
                      color: #1db4df !important;
                  }
              `}

    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        width: 120px;
        height: 40px;
        font-size: 25px;
        line-height: 25px;
    }

    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        font-size: 23px;
        line-height: 25px;
        width: 110px;
        height: 35px;
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
        /* start of phone styles */
        font-size: 15px;
        line-height: 25px;
        width: 100px;
        height: 30px;
    }
`;
interface FooterProps {
    disabled?: Boolean;
    SendVideo: Function;
    returnToAdmin: Function;
    returnToAdminLoading?: Boolean;
    setShowSpinner?: Function;
}
const LoaderBody = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
`;
const FooterBox = styled.div`
    max-width: 1200px;
    align-self: center;
    width: 100%;
    display: flex;
    @media screen and (min-width: 1199px) {
        width: 1261px;
    }
`;
export function Footer({
    disabled,
    SendVideo,
    returnToAdmin,
    returnToAdminLoading,
    setShowSpinner,
}: FooterProps) {
    return (
        <FooterBox>
            <Box>
                <ReturnToAdmin
                    onClick={() => {
                        returnToAdmin();
                    }}
                >
                    {returnToAdminLoading ? (
                        <LoaderBody>
                            <Loader size={25} color={'#fff'} />
                        </LoaderBody>
                    ) : (
                        'Return video to admin'
                    )}
                </ReturnToAdmin>

                <Send
                    disabled={disabled}
                    onClick={() => {
                        SendVideo();
                        // setShowSpinner();
                    }}
                >
                    <span className="text-truncate">Send video</span>
                </Send>
            </Box>
        </FooterBox>
    );
}
