import { useState, useEffect } from 'react';
import { Modal } from 'pages/modal';
import { useVideoJS } from 'hooks';

export const FeedBackModal = ({ title, isopen, setOpen, url }: any) => {
    const [videoURl, setVideoURl] = useState<any>('');
    const [bearer, setBearer] = useState<string>('');

    // const splitUrl = url?.split('?aes=true&aestoken=Bearer%3D');

    const { Video } = useVideoJS(
        {
            sources: [
                {
                    src: videoURl,
                    type: 'application/x-mpegURL',
                },
            ],
            controls: true,
            playbackRates: [0.5, 1, 1.5, 2],
            responsive: true,
        },
        bearer,
    );

    useEffect(() => {
        setVideoURl(url?.url);
        setBearer(url?.token);
    }, [url]);

    return (
        <Modal
            title={title}
            isOpen={isopen}
            setOpen={setOpen}
            style={{ minHeight: '560px', minWidth: '560px' }}
            ContentStyle={{ maxWidth: '1000px' }}
        >
            <Video
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    objectFit: 'fill',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                }}
            />
        </Modal>
    );
};
