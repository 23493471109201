import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import router from 'routers/router';
import { dashboardAPI } from 'services/apis';
import { Loader } from 'components';
import { ReactComponent as PlayVideoSVG } from 'assets/image/playButton.svg';
import styled, { css } from 'styled-components';
import { useNotification, useLocales } from 'hooks';
import { SUCESS_STATUS } from 'constants/auth';
import { FeedBackModal } from '../feedBackModal';
import { Modal } from 'pages/modal';
import { SureButton } from 'pages/dvr/style';

const MainDashboard = styled.div<any>`
    position: relative;
    height: ${({ open }) => (open ? '' : '140px')};
    min-height: ${({ open }) => (open ? '485px' : '')};
    transition: all 0.5s ease;
    /* margin: ${({ open }) => (open ? '0px 25px 10px 0px' : '20px 40px 20px 20px')}; */
    margin: 20px 40px 20px 20px;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        height: ${({ open }) => (open ? '' : '120px')};
        margin: ${({ open }) => (open ? '0px 0px 10px 0px ' : '0px')};
        min-height: ${({ open }) => (open ? '456px;' : '')};
    }
`;

const Box = styled.div<any>`
    padding: 0;

    ${(props) =>
        props?.open
            ? css`
                  border-radius: 20px 20px 0px 0px;
              `
            : css`
                  border-radius: 20px;
              `}
    height: 130px;
    border: 0.5px solid rgba(119, 127, 133, 0.5);
    display: flex;
    align-items: center;
    background: #fafbfd;
    cursor: pointer;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        height: 110px;
    }
`;

const CardLeftSide = styled.div`
    display: flex;
    flex-direction: row;
`;
const ImageBox = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    display: block;

    margin-left: 20px;

    @media only screen and (min-width: 600px) and (max-width: 820px) {
        margin-inline-end: 40px;
        display: block;
    }
`;

const Image = styled.img`
    inset: 0px;
    min-height: 80px;
    height: 80px;
    min-width: 120px;
    width: 120px;
    border-radius: 5px;
    overflow: hidden;
    object-fit: cover;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        width: 100px;
        height: 70px;
    }
`;

const NameBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1;
    justify-content: space-evenly;
`;

const TitleDetails = styled.div`
    display: flex;
    //flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 23px;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        font-size: 16px;
    }
`;
const TitleLabel = styled.span`
    color: rgba(119, 127, 133, 1);
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
`;
const GlobalSpanValue = styled.span`
    color: #0b141d;
    font-size: 14px;
    /* font-size: 35px; */
    font-weight: 400;
    line-height: 24px;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        font-size: 16px;
    }
`;

const CardRightSide = styled.div`
    display: flex;
    justify-content: flex-start;
    /* margin-top: 16px; */
    /* width: 100%; */
`;

const PeriodBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const TimerBox = styled.div`
    margin-right: 5%;
`;
const TimerValue = styled.div`
    font-family: 'Amaranth';
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    border-radius: 10px;
    display: flex;
    border: 0.5px solid rgba(119, 127, 133, 0.5);
    background: #fafbfd;
    justify-content: center;
    align-items: center;
    padding: 5px;
    min-width: 60px;
    max-width: 100px;
    height: 60px;
    max-width: 60px;
    max-height: 60px;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        height: 40px;
        min-width: 40px;
        font-size: 18px;
        max-width: 50px;
    }
`;
const TimerTitle = styled.span`
    display: flex;
    justify-content: center;
    color: rgba(119, 127, 133, 1);
    /* font-size: 23px; */
    font-size: 15px;

    font-weight: 300;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        font-size: 16px;
    }
`;

const StatusBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Status = styled.div`
    border: 0.5px solid rgba(119, 127, 133, 0.5);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #32383d;
    font-weight: 400;
    font-family: 'Amaranth';
    font-size: 15px;
    min-width: 100px;
    max-width: 140px;
    padding: 5px;
    height: 30px;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        font-size: 13px;
        min-width: 85px;
        width: 85px;
        height: 29px;
    }
`;

const CollapsBox = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-right: 40px;
`;
const CollapsIcon = styled.button`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 35px;
    height: 35px;
    align-items: center;
    background-color: #f0f3fd;
    border-radius: 8px;
    font-size: 25px;
    font-weight: 300;
    border: none;
    outline: none;
`;

const StatusValue = styled.span`
    color: #0b141d;
    margin-top: 4%;
    font-weight: 300;
    font-size: 15px;
`;

const UserDetails = styled.div`
    width: 49%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`;

const ExpandTilte = styled.span`
    font-weight: 500;
    font-size: 21px;
    font-family: 'Amaranth';
    @media only screen and (min-width: 321px) and (max-width: 820px) {
        font-size: 17px;
    }
`;

const ExpandLabelHeader = styled.span`
    color: rgba(119, 127, 133, 1);
    font-size: 14px;
    font-weight: 500;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        font-size: 12px;
    }
`;
const ExpandLabelValue = styled.span`
    font-style: normal;
    line-height: 23px;
    font-size: 14px;
    font-weight: 500;
    color: #0b141d;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        font-size: 12px;
    }
`;
const ExpandWrapDetalies = styled.div`
    display: flex;
    background: #fdfefed9;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 20px;
    min-height: 85px;
    max-height: 85px;
`;
interface FeedbackProps {
    feedBack?: boolean;
}
const SpanFeedbackLink = styled.button<FeedbackProps>`
    ${(props) =>
        props?.feedBack
            ? css`
                  color: gray;
                  cursor: not-allowed !important;
                  text-decoration-line: none;
              `
            : css`
                  color: #1db4df;
                  cursor: pointer;
                  text-decoration-line: underline;
              `}
    border: none;
    background: transparent;
    align-items: center;
    font-size: 16px;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        font-size: 14px;
    }
`;
const ReturnToAdmin = styled.button`
    border: none;
    background: transparent;
    word-spacing: 2px;
    font-size: 15px;
    color: #ef3156;
    text-decoration-line: underline;
    width: 100%;
    text-align: end;
    cursor: pointer;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        font-size: 21px;
    }
`;
const ReturnAdminWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const LastFeedbakDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 49%;
`;

const ExpandHeader = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        margin-inline-end: 15px;
    }
`;
const ExpandWrap = styled.div<any>`
    ${(open) =>
        open
            ? css`
                  max-height: 1200px;
                  height: max-content;
                  display: flex;
                  justify-content: space-between;
              `
            : css`
                  height: 0px;
                  max-height: 0px;
                  display: none;
              `}
    width: 100%;
    background: #fafbfd;
    border: 0.5px solid #e5e5e5;
    border-radius: 0 0 20px 20px;
    border: 0.5px solid rgba(119, 127, 133, 0.5);
    border-top: none;
    opacity: ${({ open }) => (open ? '1' : '0')};
    transition: opacity 0.5s ease;
    padding: 20px;
`;

const PlayVideoStyleSVG = styled(PlayVideoSVG)`
    position: absolute;
    left: 23%;
    top: 28%;
    @media only screen and (min-width: 321px) and (max-width: 820px) {
        display: none;
    }
`;

export function Item({ item, handelReturnToAdminVideo }: any) {
    const { trans } = useLocales();
    const dispatch = useDispatch<any>();
    const [isOpen, setIsOpen] = useState(false);
    const [VideoAdminModal, handelToVideoAdminModal] = useState(false);

    const [fetchItemDetalis, setFetchItemDetalis] = useState(false);
    const [feedBackLoading, setFeedBackLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [returnToAdminLoading, setReturnToAdminLoading] = useState(false);
    const [itemDetalis, setItemDetails] = useState<any>({});
    const [feedbackURL, setFeedbackURL] = useState<any>();
    const [loading, setLoading] = useState(false);

    const { success, error } = useNotification();

    const fetchData = async () => {
        setLoading(true);
        const { payload } = await dispatch(
            dashboardAPI.videoDetails()({ urlParams: `/${item?.videoId}` }),
        );

        if (SUCESS_STATUS.includes(payload?.status)) {
            setItemDetails(payload.data);
            setIsOpen(true);
        } else {
            const message =
                payload?.message?.message || 'Oops there was an error ! Please try again';
            error({
                message: message,
            });
        }

        setLoading(false);
    };

    const handelLastFeedbackVideo = async () => {
        if (!itemDetalis?.feedback?.id) return;
        setFeedBackLoading(true);
        await dispatch(
            dashboardAPI.feedbackView()({ urlParams: `/${itemDetalis?.feedback?.id}` }),
        ).then(({ payload }: { payload: any }) => {
            if (SUCESS_STATUS.includes(payload?.status)) {
                console.log('payload.data', payload.data);
                setFeedbackURL(payload.data);
                setShowModal(true);
            } else {
                const message =
                    payload?.message?.message || 'Oops there was an error ! Please try again';
                error({
                    message: message,
                });
            }
            setFeedBackLoading(false);
        });
    };
    const handelToVideoAdmin = () => {
        setReturnToAdminLoading(true);
        dispatch(dashboardAPI.linkToAdmin()({ urlParams: `/${item?.videoId}` })).then(
            ({ payload }: { payload: any }) => {
                handelReturnToAdminVideo(item?.videoId);
                if (SUCESS_STATUS.includes(payload?.status)) {
                    handelReturnToAdminVideo(item?.videoId);
                    success({
                        message: 'Success',
                    });
                    setIsOpen(false);
                    handelToVideoAdminModal(false);
                } else {
                    const message =
                        payload?.message?.message || 'Oops there was an error ! Please try again';
                    error({
                        message: message,
                    });
                }

                setIsOpen(false);
                setReturnToAdminLoading(false);
            },
        );
    };

    const handelOnCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (fetchItemDetalis) fetchData();
    }, [fetchItemDetalis]);

    return (
        <MainDashboard open={isOpen} className="row p-0">
            {showModal && (
                <FeedBackModal
                    url={feedbackURL}
                    isopen={showModal}
                    setOpen={handelOnCloseModal}
                    title={`${itemDetalis?.feedback?.name} - ${item?.videoId}`}
                />
            )}
            {VideoAdminModal && (
                <Modal
                    title={trans('dashboard.return.to.admin')}
                    isOpen={VideoAdminModal}
                    setOpen={() => handelToVideoAdminModal(false)}
                    // style={{ maxWidth: '100%' }}
                    ContentStyle={{ maxWidth: '500px' }}
                >
                    <span style={{ fontSize: '20px', textAlign: 'center', width: '100%' }}>
                        {' '}
                        {trans('dashboard.return.to.admin.qustion')}{' '}
                    </span>
                    <SureButton
                        onClick={handelToVideoAdmin}
                        loading={returnToAdminLoading}
                        disabled={returnToAdminLoading}
                    >
                        {trans('dashboard.sure')}
                    </SureButton>
                </Modal>
            )}
            <Box
                open={isOpen}
                onClick={() => {
                    router.navigate(`dvr`, { id: item?.videoId });
                }}
            >
                <CardLeftSide className="col-sm-6 col-7 col-xxl-7 col-xl-7 ">
                    <ImageBox className="col-sm-3 p-0 col-xl-3 col-3">
                        <Image src={item?.thumbnailUrl} />
                        <PlayVideoStyleSVG />
                    </ImageBox>
                    <NameBox className="col-7 col-sm-11 col-xl-7 p-0 m-0 ">
                        <TitleDetails>
                            <TitleLabel className="col-sm-3 col-xl-3 m-0 p-0">
                                {trans('dashboard.child.name')}
                            </TitleLabel>
                            <GlobalSpanValue>{item?.childName}</GlobalSpanValue>
                        </TitleDetails>
                        <TitleDetails>
                            <TitleLabel className="col-sm-3 m-0 p-0">
                                {trans('dashboard.video.id')}
                            </TitleLabel>
                            <GlobalSpanValue> {item?.videoId}</GlobalSpanValue>
                        </TitleDetails>
                    </NameBox>
                </CardLeftSide>
                <CardRightSide className="col-sm-4">
                    <PeriodBox className="col-sm-7">
                        <TimerBox>
                            <TimerValue className="text-truncate">{item?.deadlineHours}</TimerValue>
                            <TimerTitle>{trans('dashboard.hours')}</TimerTitle>
                        </TimerBox>
                        <TimerBox>
                            <TimerValue className="text-truncate">
                                {item?.deadlineMinutes}
                            </TimerValue>
                            <TimerTitle> {trans('dashboard.minutes')} </TimerTitle>
                        </TimerBox>
                    </PeriodBox>
                    <StatusBox>
                        <Status className="text-truncate">{item?.videoStateId}</Status>
                        <StatusValue>{item?.lastUpdateTime}</StatusValue>
                    </StatusBox>
                </CardRightSide>
                <CollapsBox>
                    {loading ? (
                        <Loader size={25} />
                    ) : (
                        <CollapsIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsOpen(!isOpen);
                                setFetchItemDetalis(!fetchItemDetalis);
                            }}
                        >
                            {isOpen ? '-' : '+'}
                        </CollapsIcon>
                    )}
                </CollapsBox>
            </Box>
            <ExpandWrap open={isOpen && !loading} className="col-sm-12">
                <UserDetails className="col-sm-6">
                    <div>
                        <ExpandTilte>{trans('dashboard.user.details')}</ExpandTilte>
                    </div>

                    <ExpandWrapDetalies className="col-sm-12">
                        <ExpandHeader className="col-sm-5">
                            <ExpandLabelHeader>{trans('dashboard.child.name')}</ExpandLabelHeader>
                            <ExpandLabelValue>{itemDetalis?.childName}</ExpandLabelValue>
                        </ExpandHeader>

                        <ExpandHeader className="col-sm-2">
                            <ExpandLabelHeader>{trans('dashboard.child.age')}</ExpandLabelHeader>
                            <ExpandLabelValue>
                                {`${itemDetalis?.dateOfBirthYears || '-'}Y ${
                                    itemDetalis?.dateOfBirthMonths || '-'
                                }M`}
                            </ExpandLabelValue>
                        </ExpandHeader>
                        <ExpandHeader className="col-sm-6">
                            <ExpandLabelHeader>{trans('dashboard.adult.name')}</ExpandLabelHeader>
                            <ExpandLabelValue>{itemDetalis?.name || '-'}</ExpandLabelValue>
                        </ExpandHeader>
                    </ExpandWrapDetalies>
                    <ExpandWrapDetalies className="col-sm-12">
                        <ExpandHeader className="col-sm-5">
                            <ExpandLabelHeader>{trans('dashboard.uploaded.on')}</ExpandLabelHeader>
                            <ExpandLabelValue>{itemDetalis?.timeUploaded || '-'}</ExpandLabelValue>
                        </ExpandHeader>
                        <ExpandHeader className="col-sm-2">
                            <ExpandLabelHeader>{trans('dashboard.duration')}</ExpandLabelHeader>
                            <ExpandLabelValue>{itemDetalis?.videoLength || '-'}</ExpandLabelValue>
                        </ExpandHeader>
                        <ExpandHeader className="col-sm-6">
                            <ExpandLabelHeader>{trans('dashboard.module.name')}</ExpandLabelHeader>
                            <ExpandLabelValue>{itemDetalis?.module_name || '-'}</ExpandLabelValue>
                        </ExpandHeader>
                    </ExpandWrapDetalies>
                    <ExpandWrapDetalies className="col-sm-12">
                        <ExpandHeader>
                            <ExpandLabelHeader>{trans('dashboard.notes')}</ExpandLabelHeader>
                            <ExpandLabelValue>{itemDetalis?.uploadComment || '-'}</ExpandLabelValue>
                        </ExpandHeader>
                    </ExpandWrapDetalies>
                </UserDetails>
                <LastFeedbakDetails className="col-sm-6">
                    <div>
                        <ExpandTilte>{trans('dashboard.last.feedback.details')}</ExpandTilte>
                    </div>

                    <ExpandWrapDetalies className="col-sm-12">
                        <ExpandHeader className="col-sm-5">
                            <ExpandLabelHeader>
                                {trans('dashboard.reviewer.name')}
                            </ExpandLabelHeader>
                            <ExpandLabelValue>
                                {itemDetalis?.feedback?.name || '-'}
                            </ExpandLabelValue>
                        </ExpandHeader>

                        <ExpandHeader className="col-sm-6">
                            <ExpandLabelHeader>
                                {trans('dashboard.practiced.module')}
                            </ExpandLabelHeader>
                            <ExpandLabelValue>
                                {itemDetalis?.feedback?.module_name || '-'}
                            </ExpandLabelValue>
                        </ExpandHeader>
                    </ExpandWrapDetalies>
                    <ExpandWrapDetalies className="col-sm-12">
                        <SpanFeedbackLink
                            onClick={handelLastFeedbackVideo}
                            feedBack={!itemDetalis?.feedback?.id ? true : false}
                        >
                            {feedBackLoading ? (
                                <Loader size={25} />
                            ) : (
                                trans('dashboard.link.last.feedback.video')
                            )}
                        </SpanFeedbackLink>
                    </ExpandWrapDetalies>

                    <ExpandWrapDetalies className="col-sm-12">
                        <ExpandHeader>
                            <ExpandLabelHeader>Notes</ExpandLabelHeader>
                            <ExpandLabelValue>
                                {itemDetalis?.feedback?.adminSummary || '-'}
                            </ExpandLabelValue>
                        </ExpandHeader>
                    </ExpandWrapDetalies>
                    <ReturnAdminWrap>
                        {/* {returnToAdminLoading ? (
                            <LoaderBody>
                                <Loader size={25} />:
                            </LoaderBody>
                        ) : ( */}
                        <div>
                            <ReturnToAdmin onClick={() => handelToVideoAdminModal(true)}>
                                {trans('dashboard.return.to.admin')}
                            </ReturnToAdmin>
                        </div>
                        {/* )} */}
                    </ReturnAdminWrap>
                </LastFeedbakDetails>
            </ExpandWrap>
        </MainDashboard>
    );
}
