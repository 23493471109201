import ClipLoader from 'react-spinners/ClipLoader';

interface LoaderProps {
    color?: string;
    size?: number;
}

export const Loader = (props: LoaderProps) => {
    return <ClipLoader {...{ ...props }} />;
};
