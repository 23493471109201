const CANVASE_WIDTH = 1280;
const CANVASE_HEIGHT = 720;
const HORIZONTAL_VIDEO_WIDTH = 400;
const VIDEO_TYPE = 'type';
const MIMETYPE = 'video/webm;codecs=h264';

const CONSTRAINTS = {
    audio: true,
    video: {
        width: { min: 0, ideal: 480, max: 720 },
        height: { min: 0, ideal: 480, max: 720 },
    },
};

const CONTAINER_DYMENTIONS = {
    width: '100%',
    height: '100%',
};

export {
    CONTAINER_DYMENTIONS,
    HORIZONTAL_VIDEO_WIDTH,
    CANVASE_HEIGHT,
    CANVASE_WIDTH,
    CONSTRAINTS,
    VIDEO_TYPE,
    MIMETYPE,
};
