import styled from 'styled-components';
import BackGround from '../../../../assets/image/background-empty.svg';

const Title = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 60px;
    margin-top: 40px;
    z-index: 2;
    > span:nth-child(1) {
        font-weight: bold;
        font-size: 45px;
        font-family: 'Amaranth';
    }
    > span:nth-child(2) {
        font-size: 45px;
        font-weight: 300;
        line-height: 1;
    }
`;

const Box = styled.div`
    width: 100%;
    height: 83%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    background-color: linear-gradient(360deg, #ffffff 17.33%, #f5f6fa 90.53%);
`;

const BackgroundEmpty = styled.div`
    background-image: url(${BackGround});
    height: 100%;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 10%;
    right: 0;
    left: 0;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        background-position: bottom;
        background-size: contain;
    }
`;
interface NoDataProps {
    reviewerName: string;
}
export function NoData({ reviewerName }: NoDataProps) {
    return (
        <Box>
            <Title>
                <span>Hi {reviewerName}</span>
                <span>Your dashboard is empty</span>
            </Title>
            <BackgroundEmpty></BackgroundEmpty>
        </Box>
    );
}
