import { useEffect, useState, useCallback } from 'react';
import hasCameraPermissions from './helper/hasCameraPermissions';
import hasMicrophonePermissions from './helper/hasMicrophonePermissions';

function useRecorderPermission() {
    const [isCamAccess, setCamAccess] = useState(false);
    const [isMicAccess, setMicAccess] = useState(false);

    const permissionListener = useCallback(async () => {
        await hasCameraPermissions({ setCamAccess });
        await hasMicrophonePermissions({ setMicAccess });
    }, []);

    const init = useCallback(async () => {
        await permissionListener();
    }, [permissionListener]);

    useEffect(() => {
        init();
    }, [init]);

    return { hasPermission: isCamAccess && isMicAccess };
}

export default useRecorderPermission;
