import { useEffect } from 'react';
import { useRouteNode } from 'react-router5';
import { pageStructure } from 'utils';
import styled from 'styled-components';
import { LockLoader } from 'pages/lockLoader/LockLoader';
import { Notification } from 'pages/notifcation';
import platform from 'platform';
import { useNotification } from 'hooks';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

const AppBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

function App({ ...props }: any) {
    const instance: PublicClientApplication = props.instance;
    const { warning } = useNotification();
    const { route } = useRouteNode('');
    const { Layout, Page } = pageStructure(route);

    const handleBrowser = () => {
        if (platform.name?.search('Chrome') == -1) {
            warning({
                message:
                    'Social Mind is compatible with Google Chrome & Chromium Engine browsers. For the best experience please use the platform with the recommended browsers',
            });
        }
    };
    useEffect(() => {
        handleBrowser();
    }, []);
    return (
        <MsalProvider instance={instance}>
            <AppBox>
                <Notification />
                <LockLoader />
                <Layout>
                    <Page />
                </Layout>
            </AppBox>
        </MsalProvider>
    );
}

export default App;
