import { audioContext } from './audioContext';
import { CONSTRAINTS, CANVASE_HEIGHT, CANVASE_WIDTH } from 'constants/recorder';

interface userMediaProps {
    cameraRef: any;
    canvasRef: any;
    videoRef: any;
}

export const userMedia = async ({ cameraRef, canvasRef, videoRef }: userMediaProps) => {
    const stream = await navigator.mediaDevices.getUserMedia(CONSTRAINTS);
    const videoStream = videoRef?.current?.captureStream?.();
    cameraRef.current.srcObject = stream;
    canvasRef.current.width = CANVASE_WIDTH;
    canvasRef.current.height = CANVASE_HEIGHT;

    const mixedOutput = audioContext({ stream, videoStream });

    const combinedStream = new MediaStream([
        ...mixedOutput.stream.getAudioTracks(),
        ...canvasRef.current.captureStream().getVideoTracks(),
    ]);

    return combinedStream;
};
