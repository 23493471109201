import React from 'react';
import styled from 'styled-components';

interface CardProps {
    children?: React.ReactNode;
}

const CardBox = styled.div`
    display: flex;
    flex-direction: column;
`;
export const Card = React.memo(({ children }: CardProps) => (
    <>
        <CardBox>{children}</CardBox>
    </>
));
