import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from '../../templates';

interface UsersState {
    entities: [];
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    currentRequestId: undefined;
    error: [];
}

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
} as UsersState;

export const videoDetails = () => {
    return requestAsyncThunk({
        storeName: 'videoDetails',
        _url: `/reviewer/video/info`,
        method: 'GET',
    });
};

export const videoDetailsSlice = createSlice({
    name: 'videoDetails',
    initialState,
    reducers: {
        resetAction: () => {
            return initialState;
        },
    },
    extraReducers: responseAsyncThunk(videoDetails()),
});

export const videoList = () => {
    return requestAsyncThunk({
        storeName: 'videoList',
        _url: `/reviewer/video/list`,
        method: 'GET',
    });
};

export const videoListSlice = createSlice({
    name: 'videoList',
    initialState,
    reducers: {
        resetAction: () => {
            return initialState;
        },
    },
    extraReducers: responseAsyncThunk(videoList()),
});

export const feedbackView = () => {
    return requestAsyncThunk({
        storeName: 'feedbackView',
        _url: `reviewer/feedback/view`,
        method: 'POST',
    });
};

export const feedbackViewSlice = createSlice({
    name: 'feedbackView',
    initialState,
    reducers: {
        resetAction: () => {
            return initialState;
        },
    },
    extraReducers: responseAsyncThunk(feedbackView()),
});

export const linkToAdmin = () => {
    return requestAsyncThunk({
        storeName: 'linkToAdmin',
        _url: `reviewer/video/unassign`,
        method: 'POST',
    });
};

export const linkToAdminSlice = createSlice({
    name: 'linkToAdmin',
    initialState,
    reducers: {
        resetAction: () => {
            return initialState;
        },
    },
    extraReducers: responseAsyncThunk(linkToAdmin()),
});
export const getReviewerInfo = () => {
    return requestAsyncThunk({
        storeName: 'ReviewerInfo',
        _url: `/reviewer/info`,
        method: 'GET',
    });
};
