import styled from 'styled-components';

import { useController } from 'react-hook-form';
import React from 'react';
import { capitalize } from 'lodash';
import { ReactComponent as EyeHide } from 'assets/icons/eye-slash-solid.svg';
import { ReactComponent as EyeShow } from 'assets/icons/eye-solid.svg';
interface InputProps {
    type: React.HTMLInputTypeAttribute;
    placeholder?: any;
    label?: String;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    value?: String | Number | any;
    name?: String | any;
    pattern?: String | any;
    required?: Boolean | any;
    control: any;
    defaultValue: string;
    setPasswordToggle?: Function;
    passwordToggle?: boolean;
}
const InputHeader = styled.span`
    text-align: left;
    font-size: 15px;
    color: #777f85;
    font-weight: 300;
`;
const InputField = styled.input`
    border: none;
    background: rgba(253, 254, 254, 0.85);
    border: ${({ error }: any) => (error ? '1px solid #e94435' : '')};
    position: relative;
    width: 420px;
    height: 48px;
    padding: 0 10px;
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        border: ${({ error }: any) => (error ? '1px solid #e94435' : '')} !important;
        -webkit-box-shadow: 0px 1px 20px 20px rgb(253 254 254 / 85%) inset;
    }
    &:focus-visible {
        outline: none;
    }
    ::placeholder,
    ::-webkit-input-placeholder {
        color: rgba(253, 254, 254, 0.85);
    }
    :-ms-input-placeholder {
        color: rgba(253, 254, 254, 0.85);
    }
    &:-webkit-autofill::first-line {
        font-weight: 300;
        font-size: 25px;
        line-height: normal;
    }
    outline: none;
    font-weight: 300;
    font-size: 25px;
    line-height: normal;
`;

const Box = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    width: fit-content;
    margin-top: 20px;
`;
const HintError = styled.span`
    color: #e94435;
    font-weight: 300 !important;
    display: flex;
    font-weight: lighter;
    align-items: flex-start;
    margin-top: 3px;
    font-size: 15px;
`;
const Hint = ({ children }: any) => {
    return <HintError>{children}</HintError>;
};
const InputBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;
const EyeHideImage = styled(EyeHide)`
    position: absolute;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;
const EyeShowImage = styled(EyeShow)`
    position: absolute;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;
export function Input({
    control,
    name,
    label,
    defaultValue = '',
    passwordToggle,
    setPasswordToggle,
    ...rest
}: React.InputHTMLAttributes<HTMLInputElement> & InputProps) {
    const handleEye = (data: any) => {
        data?.setPasswordToggle(!data?.passwordToggle);
    };

    const {
        field,
        formState: { errors },
    } = useController({
        control,
        name,
        defaultValue,
    });
    return (
        <Box>
            <InputHeader>{label}</InputHeader>
            <InputBox>
                <InputField
                    {...{ ...field, ...rest, error: errors[name] }}
                    type={name == 'password' && passwordToggle ? 'password' : 'text'}
                />
                {name == 'password' &&
                    (passwordToggle ? (
                        <EyeHideImage
                            onClick={() =>
                                handleEye({
                                    setPasswordToggle,
                                    passwordToggle,
                                })
                            }
                        />
                    ) : (
                        <EyeShowImage
                            onClick={() =>
                                handleEye({
                                    setPasswordToggle,
                                    passwordToggle,
                                })
                            }
                        />
                    ))}
            </InputBox>
            <Hint>{capitalize(errors[name]?.message)}</Hint>
        </Box>
    );
}
