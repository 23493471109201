import React from 'react';
import { useSelector } from 'react-redux';
import { Templates } from './Templates';
import styled from 'styled-components';

const Body = styled.div<any>`
    background: ${(props) => props.background};
    padding: 0px 25px;
    color: rgb(255, 255, 255);
    position: fixed;
    z-index: 13000;
    top: 0px;
    height: 31px;
    left: 0px;
    right: 0px;
    margin-top: ${(props) => (props.visable ? '0px' : '-30px')};
    transition: all 0.5s ease;
`;

export function Notification() {
    const { visable, color } = useSelector<any>((state) => state.notifcation) as any;

    return (
        <Body visable={visable} background={color} className="d-flex align-items-center">
            {<Templates />}
        </Body>
    );
}
