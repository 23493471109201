import React, { useRef, forwardRef, useState } from 'react';
import { Controls } from './contorls/Controls';
import videojs from 'video.js';

import 'video.js/dist/video-js.css';
interface videoProps {
    style: any;
    playing: any;
    handleSetPlaying: any;
    duration: string;
    bearer?: string;
    videoPlayer?: any;
    src: string;
    showInfoModal?: Boolean;
    returnHomeModal?: Boolean;
    sendVedioModal?: Boolean;
    setReady: any;
}

export const Player = forwardRef(
    (
        {
            handleSetPlaying,
            playing,
            style,
            duration,
            bearer,
            src,
            showInfoModal,
            returnHomeModal,
            sendVedioModal,
            setReady,
            ...rest
        }: videoProps & React.VideoHTMLAttributes<HTMLVideoElement>,
        ref: any,
    ) => {
        const player = React.useRef<any>(null);

        const seekRef = useRef<any>(null);
        const [seek, setSeek] = useState<number>(0);
        const [time, setTime] = useState<string>('00:00');

        const timeFormat = (sec: any): string => {
            const minutes: number = Math.floor(sec / 60);
            const seconds: number = Math.floor(sec % 60);

            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        };
        const handleUpdateSeekBar = () => {
            if (!ref.current?.duration || !ref.current?.currentTime) return null;

            setSeek((100 / ref.current?.duration) * ref.current?.currentTime);
            setTime(timeFormat(ref.current?.currentTime));
            let condition = ref.current?.currentTime === ref.current?.duration;
            if (condition) {
                handleSetPlaying(false);
                setSeek(0);
                setTime('00:00');
            }
        };

        const handlePlayPause = () => {
            if (ref.current?.paused) {
                ref.current?.play();
            } else {
                ref.current?.pause();
            }

            handleSetPlaying(!ref.current?.paused);
        };

        const handleForwad = () => {
            if (!ref.current?.duration || !ref.current?.currentTime) return null;
            if (ref.current?.currentTime + 2 <= ref.current?.duration) {
                ref.current.currentTime += 2;
            } else {
                ref.current.currentTime = ref.current?.duration;
            }
        };

        const handleBackward = () => {
            if (!ref.current?.duration || !ref.current?.currentTime) return null;

            if (ref.current.currentTime - 2 >= 0) {
                ref.current.currentTime -= 2;
            } else {
                ref.current.currentTime = 0;
            }
        };

        const handleSeekBarChange = async () => {
            setTimeout(() => {
                ref.current?.play();
                // ref.current?.pause();
            }, 500);
            // if (!ref.current?.duration || !ref.current?.currentTime) return null;

            ref.current.currentTime = Number(
                ref.current?.duration * (Number(seekRef.current.value) / 100),
            );
            setSeek((100 / ref.current?.duration) * ref.current.currentTime);

            setTimeout(() => {
                ref.current?.pause();
            }, 500);
            setTimeout(() => {
                handlePlayPause();
            }, 500);
        };
        React.useEffect(() => {
            if (showInfoModal === true || returnHomeModal === true || sendVedioModal === true) {
                ref.current?.pause();
                handleSetPlaying(false);
            }
        });

        const setupTokenForDecrypt = (options: any) => {
            if (options.uri.includes('keydeliver')) {
                options.headers = options.headers || {};
                options.headers.Authorization = `Bearer ${bearer}`;
            }
            return options;
        };

        React.useEffect(() => {
            if (!ref?.current) return;

            player.current = videojs(
                ref.current,
                {
                    sources: [
                        {
                            src,
                            type: 'application/x-mpegURL',
                        },
                    ],
                    controls: false,
                    playbackRates: [0.5, 1, 1.5, 2],
                    responsive: true,
                },
                function onPlayerReady() {
                    // console.log('onPlayerReady');
                },
            );
            (videojs as any).Vhs.xhr.beforeRequest = setupTokenForDecrypt;
        }, [ref]);

        return (
            <>
                <>
                    <div data-vjs-player>
                        <video
                            ref={ref}
                            style={{
                                ...style,
                                position: 'absolute',
                                paddingLeft: '30px',
                                paddingRight: '30px',
                            }}
                            onTimeUpdate={handleUpdateSeekBar}
                            onClick={() => handlePlayPause()}
                            onLoadedData={() => setReady(true)}
                            className="video-js"
                            id="video-player"
                            crossOrigin="anonymouse"
                        >
                            <track kind="subtitles" srcLang="en" label="English" />
                        </video>
                    </div>
                </>
                {player?.current?.readyState?.() >= 0 && (
                    <Controls
                        playing={playing}
                        duration={duration}
                        seek={seek}
                        time={time}
                        seekRef={seekRef}
                        handleSeekBarChange={handleSeekBarChange}
                        handlePlayPause={handlePlayPause}
                        handleBackward={handleBackward}
                        handleForwad={handleForwad}
                    />
                )}
            </>
        );
    },
);
