import styled from 'styled-components';

const DefaultBox = styled.div`
    display: flex;
    flex-direction: column;
    background: transparent;
    overflow: auto;
    height: 100%;
    flex-grow: 1;
    width: 93%;
    margin-right: 25px !important;
    margin-left: auto !important;
`;

export function Box({ children }: any) {
    return <DefaultBox className="col-sm-10 ">{children}</DefaultBox>;
}
