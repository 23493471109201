import * as pages from 'pages';
import {
    DashboardLayout,
    AuthLayout,
    //  POSLayout,
    VideoContainer,
} from 'layout';
import { get, includes, isEmpty, find } from 'lodash';

const pageRoutes = {
    signin: {
        Page: pages.Signin,
        Layout: AuthLayout,
    },
    home: {
        Page: pages.Dashboard,
        Layout: DashboardLayout,
    },
    dvr: {
        Page: pages.VideoContainer,
        Layout: VideoContainer,
    },
    // poc: {
    //     Page: pages.POC,
    //     Layout: POSLayout,
    // },
    '404': {
        Page: pages.NotFound,
        Layout: AuthLayout,
    },
    loading: {
        Page: pages.Loading,
        Layout: DashboardLayout,
    },
};

const pageStructure = (route: any): any => {
    if (isEmpty(route)) {
        return pageRoutes.loading;
    }

    const routePaths = Object.keys(get(route, 'meta.params', {}));

    const pageRoute =
        find(routePaths.reverse(), (route: string) => includes(Object.keys(pageRoutes), route)) ||
        '404';
    return pageRoutes[pageRoute as keyof typeof pageRoutes];
};

export default pageStructure;
