import { combineReducers } from '@reduxjs/toolkit';
import localforage from 'localforage';
import { persistReducer } from 'redux-persist';
import { dashboardAPI, authAPI } from 'services/apis';
import lockLoaderReducer from 'pages/lockLoader/lockLoaderSlice';
import notificationReducer from 'pages/notifcation/notificationSlice';

const authPersistConfig = {
    key: 'auth',
    storage: localforage,
    whiteList: ['status'],
};

const authPersistReducer = persistReducer(authPersistConfig, authAPI.authSlice.reducer);

const reducers = combineReducers({
    auth: authPersistReducer,
    videoList: dashboardAPI.videoListSlice.reducer,
    videoDetails: dashboardAPI.videoDetailsSlice.reducer,
    feedbackView: dashboardAPI.feedbackViewSlice.reducer,
    linkToAdmin: dashboardAPI.linkToAdminSlice.reducer,
    lockLoader: lockLoaderReducer,
    notifcation: notificationReducer,
});

export default reducers;
