import { default as axiosDefault } from 'axios';
import {
    withVersioning,
    VersioningStrategy,
    AxiosInstanceWithVersioning,
} from 'axios-api-versioning';
import { loginRequest } from 'config/azureAuthConfig';

import { msalInstance } from '../../index';

const BACKEND_API_ENDPOINT = process.env.REACT_APP_BACKEND_API_ENDPOINT;

if (process.env.NODE_ENV === 'development' && !BACKEND_API_ENDPOINT) {
    console.log(
        'BACKEND_API_ENDPOINT config value not found. Please check your environment variables.',
    );
}

export const baseClient = axiosDefault.create({
    baseURL: `${BACKEND_API_ENDPOINT}`,
});

export const clientAction: AxiosInstanceWithVersioning = withVersioning(baseClient, {
    apiVersion: '',
    versioningStrategy: VersioningStrategy.UrlPath,
});

const axios = async ({ url, method, params = {}, ...rest }: any) => {
    const token = await getAccessToken();

    const config = {
        url,
        method,
        data: {},
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    switch (method) {
        case 'POST':
        case 'PATCH':
        case 'PUT': {
            config.data = params;
            break;
        }

        default:
            break;
    }

    return await clientAction({
        ...config,
        ...rest,
    });
};

const getAccessToken = async (): Promise<any> => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        return null;
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
    });

    return response.accessToken ?? null;
};

export default axios;
