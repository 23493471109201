import styled from 'styled-components';
import { useLocales } from 'hooks';
const Box = styled.div`
    margin-top: 30px;
    display: flex;
    background-color: transparent;
    position: relative;
    padding-left: 20px;
    padding-right: 31px;
    margin-bottom: 10px;
    > * {
        font-weight: 300;
        /* font-size: 35px; */
        font-size: 25px;
    }
`;
const VideoBox = styled.span`
    margin-inline-end: 74px;
    margin-inline-start: 6px;
`;
const DetailsBox = styled.span`
    margin-inline-end: 10px;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        margin-inline-end: 51px;
    }
`;
const TimerBox = styled.span`
    margin-inline-end: 28px;
    @media only screen and (min-width: 600px) and (max-width: 820px) {
        margin-inline-end: 16px;
    }
`;
const StatusBox = styled.span`
    margin-inline-end: 24px;
`;
export function Header() {
    const { trans } = useLocales();

    return (
        <Box>
            <VideoBox className="col-sm-1">{trans('dashboard.video')}</VideoBox>
            <DetailsBox className="col-5 col-xl-5 col-sm-3">
                {trans('dashboard.details')}
            </DetailsBox>
            <TimerBox className="col-2">{trans('dashboard.timer')}</TimerBox>
            <StatusBox className="col-2">{trans('dashboard.status')}</StatusBox>
        </Box>
    );
}
