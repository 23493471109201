import { delay } from 'lodash';
import { useRef, useState, useEffect } from 'react';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { userMedia } from './handlers';
import { HORIZONTAL_VIDEO_WIDTH } from 'constants/recorder';

export const useRecorder = () => {
    const [recorderController, setRecorder] = useState<RecordRTCPromisesHandler>();

    const cameraRef = useRef<any>(null);
    const videoRef = useRef<any>(null);
    const canvasRef = useRef<any>(null);
    const [isReady, setReady] = useState(false);
    const outputVideoRef = document
        .getElementById('video-player')
        ?.getElementsByTagName('video')[0] as any;

    useEffect(() => {
        if (!canvasRef.current || !videoRef.current || !cameraRef.current || !isReady) return;
        const getUserMedia = async () => {
            const combinedStream = await userMedia({ cameraRef, videoRef, canvasRef });
            const recorder = new RecordRTCPromisesHandler(combinedStream, {
                type: 'video',
                mimeType: 'video/webm;codecs=h264',
            });
            setRecorder(recorder);
        };

        delay(getUserMedia, 100);
    }, [cameraRef, canvasRef, videoRef, isReady]);

    useEffect(() => {
        if (!canvasRef.current || !videoRef.current || !cameraRef.current) return;

        const horizontalVideo = videoRef.current.videoHeight > videoRef.current.videoWidth;

        const interval = setInterval(async () => {
            const context = canvasRef?.current?.getContext?.('2d');
            const canvasCurrent = canvasRef.current;
            const cameraCurrent = cameraRef.current;

            if (horizontalVideo) {
                {
                    context.fillStyle = 'rgba(49, 56, 70)';
                    context.fillRect(0, 0, canvasCurrent.width, canvasCurrent.height);
                    context?.drawImage?.(
                        videoRef.current,
                        canvasCurrent?.width / 2 - HORIZONTAL_VIDEO_WIDTH / 2,
                        0,
                        HORIZONTAL_VIDEO_WIDTH,
                        canvasCurrent?.height,
                    );
                }
            } else
                context?.drawImage?.(
                    videoRef.current,
                    0,
                    0,
                    canvasCurrent?.width,
                    canvasCurrent?.height,
                );

            context?.scale(-1, 1);
            context?.drawImage?.(cameraCurrent, -canvasCurrent?.width, 0, 251, 251);
        }, 1000 / 30);

        return () => clearInterval(interval);
    });

    return {
        outputVideoRef,
        canvasRef,
        cameraRef,
        videoRef,
        recorderController,
        setReady,
        isReady,
    };
};
