import router from 'routers/router';

const errorResponse = (store: any) => {
    return [
        (response: any) => {
            return response;
        },
        async (err: any) => {
            const originalConfig = err.config;
            if (originalConfig.url !== '/login/reviewer/user' && err.response) {
                if (err.response.status === 401) {
                    try {
                        router.navigate('signin', {}, { replace: true });
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        },
    ];
};

export default errorResponse;
