import React from 'react';
import styled, { css, CSSObject } from 'styled-components';
import { ReactComponent as Close } from 'assets/image/close.svg';
interface ModalProps {
    children?: React.ReactNode;
    isOpen: Boolean;
    setIsOpen: Function;
    title?: React.ReactNode;
    infoModal?: Boolean;
    Style?: CSSObject;
    sendModal?: Boolean;
    flag: Boolean;
}
const ModalBox = styled.div<ModalProps>`
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    transition: opacity 0.5s ease;
    ${(props) =>
        props.isOpen
            ? css`
                  display: flex;
                  /* justify-content: center; */
                  flex-direction: column;
                  /* opacity: 1; */
                  height: 100%;
                  /* transition: opacity 10ms ease; */
              `
            : css`
                  display: none;
                  /* opacity: 0; */
                  height: 0px;
                  /* transition: opacity 10ms ease; */
              `}

    position: fixed;
    z-index: 5000000;
    width: 100%;
    /* max-width: 1200px; */
    overflow: auto;
    top: 0;

    ${(props) =>
        props.infoModal
            ? css`
                  background-color: rgba(0, 0, 0, 0.6);
                  backdrop-filter: blur(24px);
              `
            : css`
                  background-color: rgba(0, 0, 0, 0.4);
                  /* opacity: 0.7; */
              `}

    align-items: center;
`;
const CloseButton = styled.button<any>`
    width: fit-content;
    height: fit-content;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    right: 0;
    cursor: pointer;
    padding-right: 0;
    width: 40px;
    /* height: 40px; */
    height: 100%;
    display: flex;
    align-items: flex-start;

    ${(props) =>
        props.flag
            ? css`
                  margin-right: 6px;
                  margin-top: 0;
              `
            : css`
                  margin-right: 20px;
                  margin-top: 20px;
              `}
`;
const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    /* margin-bottom: 2%; */
`;
const Title = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    font-size: 24px;
`;
const ModalBody = styled.div<ModalProps>`
    display: flex;
    height: max-content;
    /* width: max-content; */
    flex-direction: column;
    flex-direction: column;
    background: #1e2633;
    ${(props) =>
        props.Style?.borderRadius
            ? css`
                  border-radius: ${props.Style?.borderRadius};
              `
            : css`
                  border-radius: 20px;
              `}
    /* padding: 1%; */
    max-width: 1200px;
    height: max-content;
    position: relative;
    ${(props) =>
        css`
            ${props.Style}
        `}
`;

export function Modal({
    children,
    isOpen,
    setIsOpen,
    title,
    infoModal,
    Style,
    sendModal,
    flag,
    ...rest
}: ModalProps) {
    return (
        <ModalBox isOpen={isOpen} setIsOpen={setIsOpen} infoModal={infoModal} flag={flag}>
            <ModalBody style={Style} isOpen={isOpen} setIsOpen={setIsOpen} flag={flag}>
                <ModalHeader>
                    <Title>{title}</Title>
                    <CloseButton onClick={() => setIsOpen(false)} flag={flag}>
                        <Close />
                    </CloseButton>
                </ModalHeader>
                {children}
            </ModalBody>
        </ModalBox>
    );
}
