import styled, { css } from 'styled-components';
interface ReplayBoxProps {
    showReplay: Boolean;
}
interface ControlsProps {
    outputSrc?: Number | undefined;
    left?: Boolean;
}
import { Button } from 'components';
export const LoaderBody = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const RepalyBox = styled.div<ReplayBoxProps>`
    width: 100%;
    height: 100%;
    position: relative;
    ${(props) =>
        props.showReplay
            ? css`
                  display: flex;
                  flex-direction: column;
              `
            : css`
                  display: none;
              `}
`;

export const Box = styled.div`
    background-color: #0b141d;
    display: flex;
    flex-direction: column;
    /* height: 80%; */
    /* height: calc(100vh - 16px); */

    /* max-width: 1200px; */
    /* width: 100%; */
    max-height: 646px;
    height: 53.9vw;
    width: 100vw;
    max-width: 1200px;
    max-height: 646px;
    margin: 0 auto;
    min-height: 400px;
`;

export const HiddenCanvas = styled.canvas`
    visibility: hidden;
    display: none;
    padding-left: 30px;
    padding-right: 30px;
    background-color: red;
`;
interface ControlsButtonProps {
    outputSrc?: any;
    isRecording?: boolean;
}
export const ControlsButton = styled(Button)<ControlsButtonProps>`
    ${(props) =>
        props.disabled
            ? css`
                  &:disabled {
                      background: transparent !important;

                      color: rgba(245, 188, 61, 0.5) !important;
                      border: 2px solid rgba(245, 188, 61, 0.5) !important;
                      > span {
                          color: rgba(245, 188, 61, 0.5) !important;
                      }
                  }
              `
            : css`
                  color: #f5bc3d;
                  border: 2px solid #f5bc3d !important;
                  > span {
                      color: rgba(245, 188, 61, 1) !important;
                  }
              `}
    padding: 1%;
    background: transparent !important;

    border-radius: 5px;

    width: 15vw;
    height: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    margin-left: 25px;

    line-height: 25px;
    width: 200px;
    height: 41px;
    > span {
        /* font-size: 16px !important; */
        font-weight: 400;
        margin: 0;
        @media screen and (min-width: 1200px) {
            /* start of large tablet styles */
            font-size: 16px !important;
        }
        @media screen and (min-width: 991px) and (max-width: 1200px) {
            /* start of medium tablet styles */
            font-size: 14px !important;

            /* width: 15%; */
        }

        @media screen and (min-width: 767px) and (max-width: 991px) {
            /* start of phone styles */
            /* width: 20%; */
            font-size: 12px !important;
        }
        @media screen and (min-width: 600px) and (max-width: 767px) {
            /* start of phone styles */
            /* width: 20%; */
            font-size: 11px !important;
        }
    }
`;

export const ControlsBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    /* max-height: 141px; */
    background: rgba(55, 64, 80, 255);
    height: 20%;
`;
export const VedioBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
export const ControlsLeftSide = styled.div<ControlsProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1261px;
    align-self: center;
    ${(props) =>
        props.left
            ? css`
                  align-items: center;
                  /* padding-top: 1%; */
                  flex-direction: row-reverse;
                  margin-top: 16px;
                  margin-bottom: 16px;
              `
            : css`
                  align-items: flex-start;
                  /* padding-bottom: 1%; */
                  padding-bottom: 16px;
              `}
    height: 50%;
    justify-content: space-between;
    /* margin-top: 20px; */
`;
export const ControlsRightSide = styled.div`
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    flex-direction: column;
    align-items: center;
    width: 17%;
`;
export const ControlsWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* height: 100%; */
    /* width: 33%; */
    justify-content: end;
    height: 70%;
`;
export const VideoLayout = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(55, 64, 80, 255);
`;
export const Spinner = styled.div`
    position: absolute;
    width: 100%;
    max-width: 1200px;
    top: 40%;
    display: flex;
    justify-content: center;
    z-index: 22;
`;
export const BlockPermission = styled.div`
    position: absolute;
    width: 100%;
    max-width: 1200px;
    top: 0;
    font-size: xxx-large;

    display: flex;
    justify-content: center;
    z-index: 22;
    height: 100%;
    background: #00000085;
    flex-direction: column;
`;
export const BlockPermissionSpan = styled.div`
    color: red;
    display: flex;
    justify-content: center;
`;
export const HeaderModal = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: rgba(250, 251, 253, 1);
    /* font-weight: 700;
    font-size: 45px;
    line-height: 55px; */
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    width: 100%;
    > span:nth-child(1) {
        font-weight: 300;
        font-size: 35px !important;
        line-height: 35px;
    }
    > span:nth-child(2) {
        font-family: 'Lato', sans-serif;
        width: 400px;
        margin-top: 1%;
        margin-bottom: 5%;
        font-weight: 400;
        font-size: 20px !important;
        line-height: 30px;
    }
    > div {
        font-family: 'Lato', sans-serif;
        width: 400px;
        background: linear-gradient(360deg, #ffffff 17.33%, #f5f6fa 90.53%);
        -webkit-background-clip: text;
        /* -webkit-text-fill-color: transparent; */
        background-clip: text;
        /* text-fill-color: transparent; */
        font-weight: 400;
        font-size: 20px !important;
        line-height: 30px;
    }
`;
export const HeaderModal2 = styled(HeaderModal)`
    flex-direction: column;
    > span:nth-child(2) {
        font-family: 'Lato', sans-serif;
        width: 400px;
        margin-top: 1%;
        margin-bottom: 5%;
        font-weight: 300;
        font-size: 20px !important;
        line-height: 30px;
    }
`;
export const HeaderModal3 = styled(HeaderModal)`
    flex-direction: column;
    text-align: center;
`;
export const ModuleName = styled.span`
    color: #55c7e7 !important;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
`;
interface HeaderProps {
    last?: Boolean;
}
export const HeaderTitle = styled.h4<HeaderProps>`
    width: 46%;
    color: #c9eef8;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    ${(props) =>
        props.last
            ? css`
                  padding-left: 0;
              `
            : css`
                  padding-left: 20px;
              `}
    margin-top: 25px;
    margin-bottom: 15px;
`;
export const DeleteHeaderModal = styled(HeaderModal)`
    width: 100%;
    margin-top: 0;
    color: #c9eef8;
    margin-bottom: 15px;
    font-family: 'Amaranth' !important;
`;
export const HeaderTitle2 = styled.h4`
    margin: 0;
    color: rgba(250, 251, 253, 1);
    font-weight: 700;
    font-size: 45px;
    line-height: 55px;
    font-style: normal;
`;
export const TimerSpan = styled.span`
    /* font-size: 20px; */
    color: #fff;
    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        font-size: 16px;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        font-size: 14px;

        /* width: 15%; */
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
        /* start of phone styles */
        /* width: 20%; */
        font-size: 12px;
    }
    @media screen and (min-width: 600px) and (max-width: 767px) {
        /* start of phone styles */
        /* width: 20%; */
        font-size: 10px;
    }
`;
export const TimerBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* width: 13rem;
     */
    margin-left: 30px;

    align-items: center;
`;
export const InfoModalMody = styled.div`
    display: flex;
    justify-content: space-between;
    color: #c9eef8;
    width: 100%;
    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        max-width: 1080px;
        min-width: 1080px;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        max-width: 900px;
        min-width: 900px;
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
        /* start of phone styles */
        max-width: 700px;
        min-width: 700px;
    }
`;
export const BoadyDeleteModal = styled.div`
    display: flex;
    justify-content: space-between;
    color: #c9eef8;
    max-width: 1080px;
    width: 100%;
    height: 100%;
`;
export const SendModal = styled.div`
    display: flex;
    justify-content: space-between;
    color: #c9eef8;

    width: 100%;
    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        max-width: 550px;
        min-width: 490px;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        max-width: 490px;
        min-width: 400px;
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
        /* start of phone styles */
        max-width: 440px;
        min-width: 200px;
    }
`;
export const CardModal = styled.div`
    border-radius: 10px;
    width: 100%;
    /* padding: 4%; */
    margin-right: 5px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    /* margin-top: 3%; */
    background: #0b141d;
    min-height: 98px;
`;
export const SendCardModal = styled(CardModal)`
    align-items: flex-end;
    background: transparent;
    padding: 0;
    margin-top: 0;
    width: 100%;
`;
export const TitleDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 2%;
    font-size: 35px;
`;

export const SendTitle = styled(TitleDetails)`
    align-items: flex-end;
    padding: 0;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 0;
`;
export const TitleVlaue = styled.span`
    font-style: normal;
    font-weight: 400;
    line-height: 23px;

    color: #fafbfd;
    font-size: 18px;
`;
export const ReturnText = styled(TitleVlaue)`
    text-align: center;
    width: 30rem;
    display: flex;
    justify-content: center;
    margin-top: 5%;
    font-size: 35px;
    margin-bottom: 3%;
`;
export const SureButton = styled(Button)`
    padding: 1%;
    /* background: #1b202e; */
    background: transparent !important;
    color: #1db4df !important;
    border: 2px solid #1db4df !important;
    border-radius: 5px;
    width: max-content;
    line-height: 24px;

    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        font-size: 16px !important;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        font-size: 14px !important;

        /* width: 15%; */
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
        /* start of phone styles */
        /* width: 20%; */
        font-size: 12px !important;
    }
    @media screen and (min-width: 600px) and (max-width: 767px) {
        /* start of phone styles */
        /* width: 20%; */
        font-size: 11px !important;
    }
    margin: 0 auto;
    margin-top: 5%;
    margin-bottom: 5%;
    /* font-size: 15px !important; */

    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const ReturnAdminDialog = styled(SendTitle)`
    flex-direction: row;
    justify-content: space-evenly;
`;
export const Send = styled(Button)`
    padding: 1vh;
    background: #1b202e !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    > span:nth-child(1) {
        color: #1db4df !important;
        line-height: 24px;
        @media screen and (min-width: 1199px) {
            /* start of large tablet styles */
            font-size: 16px !important;
        }
        @media screen and (min-width: 991px) and (max-width: 1199px) {
            /* start of medium tablet styles */
            font-size: 14px !important;

            /* width: 15%; */
        }

        @media screen and (min-width: 767px) and (max-width: 991px) {
            /* start of phone styles */
            /* width: 20%; */
            font-size: 12px !important;
        }
        @media screen and (min-width: 600px) and (max-width: 767px) {
            /* start of phone styles */
            /* width: 20%; */
            font-size: 11px !important;
        }
        font-weight: 300;
    }
    flex-direction: column;
    align-items: flex-end;
    color: #1db4df !important;
    border: 2px solid #1db4df !important;
    font-weight: 400;
    font-size: 25px !important;
    line-height: 25px;
    align-items: center;
    width: 121px;
    height: 41px;
    margin-top: 30px;
`;
export const SendYes = styled(ControlsButton)`
    width: 8vw;
    height: 2.5vw;
`;
export const SendVedioFeedBack = styled.textarea`
    font-family: 'Lato', sans-serif;
    resize: none;

    border: none;
    color: rgba(250, 251, 253, 1);
    font-size: 20px;
    background: #0b141d;
    width: 100%;
    padding: 10px;
    border-radius: 5px;

    @media screen and (min-width: 1199px) {
        /* width: 490px; */
        height: 290px;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* width: 490px; */
        height: 290px;
    }

    @media screen and (min-width: 700px) and (max-width: 991px) {
        /* width: 400px; */
        height: 200px;
    }
    @media screen and (max-width: 700px) {
        /* width: 350px; */
        height: 150px;
    }
`;
export const VedioCamera = styled.video`
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 312312;
    object-fit: cover;
    background-color: #000;
    transform: scale(-1, 1);
    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        width: 251px;
        height: 251px;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        width: 230px;
        height: 230px;
    }

    @media screen and (min-width: 700px) and (max-width: 991px) {
        /* start of phone styles */
        width: 200px;
        height: 200px;
    }
    @media screen and (max-width: 700px) {
        /* start of phone styles */
        width: 150px;
        height: 150px;
    }
`;
export const UserInfoDetails = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 49%;
    padding-left: 20px;
    padding-bottom: 20px;
`;
export const LastFeedbakDetails = styled.div`
    display: flex;
    flex-direction: column;
    width: 49%;
    justify-content: unset;
    padding-right: 20px;
`;
export const TitleLabel = styled.span`
    margin-bottom: 2%;

    color: #c9eef8;
    font-size: 15px;
`;

export const NoData = styled.div`
    width: 100%;
    height: 100%;
    font-size: xxx-large;
    text-align: center;
    color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const NotBlinkRecourding = styled.div`
    display: flex;
    justify-content: center;
    /* width: 7%; */
    > span {
        display: inline-block;
        background-color: #ccc;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-left: 30px;
    }
    span:nth-child(2) {
        animation-delay: 0.1s;
    }
`;
export const BlinkRecourding = styled.div`
    display: flex;
    justify-content: center;
    /* width: 7%; */
    > span {
        display: inline-block;
        background-color: #ccc;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        /* margin-right: 7px; */
        margin-left: 30px;
        animation-name: blink;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
    }
    span:nth-child(2) {
        animation-delay: 0.1s;
    }

    @keyframes blink {
        0% {
            background-color: rgba(195, 205, 213, 0.2);
        }
        20% {
            background-color: red;
            transform: scale(1.2);
        }
        100% {
            background-color: rgba(195, 205, 213, 0.2);
        }
    }
`;
export const FeedBackAbout = styled.span`
    font-weight: 100;
    width: 196px;
    margin-right: 10px;
`;
export const TitleVlaueDelete = styled.span`
    font-style: normal;
    font-weight: 300;
    line-height: 23px;
    font-weight: 400;
    color: #fafbfd;
    font-size: 35px;
`;
export const ReturnTextDelete = styled(TitleVlaue)`
    text-align: center;

    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 3%;
    font-weight: 300;
    width: 100%;
    text-align: center;
    width: 30rem;
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 3%;
    font-weight: 300;
    width: 100%;
`;
export const DeleteCardModal = styled.div`
    background: #0b141d;
    border-radius: 10px;
    width: 100%;
    /* padding: 4%; */
    margin-right: 5px;
    display: flex;
    justify-content: space-around;
    /* margin-bottom: 2%; */
`;
export const TitleDeleteDetails = styled(TitleDetails)`
    padding: 0;
`;
