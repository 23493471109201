import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from '../../templates';

interface UsersState {
    entities: [];
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    currentRequestId: undefined;
    error: [];
    status: number;
}

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
    status: 505,
} as UsersState;

export const signIn = () => {
    return requestAsyncThunk({
        storeName: 'auth',
        _url: `/login/reviewer/user`,
        method: 'POST',
    });
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAction: () => {
            return initialState;
        },
    },
    extraReducers: responseAsyncThunk(signIn()),
});

export const refresh = () => {
    return requestAsyncThunk({
        storeName: 'refresh',
        _url: `/reviewer/token/renew`,
        method: 'GET',
    });
};

export const refreshSlice = createSlice({
    name: 'refresh',
    initialState,
    reducers: {
        resetAction: () => {
            return initialState;
        },
    },
    extraReducers: responseAsyncThunk(signIn()),
});
