import { Modal } from 'components';

import {
    BoadyDeleteModal,
    DeleteCardModal,
    TitleDeleteDetails,
    ReturnTextDelete,
    SureButton,
    HeaderModal,
    DeleteHeaderModal,
} from '../style';

export const DeleteModal = ({ deleteModal, handleDeleteRecording, setDeleteModal }: any) => {
    const TitleDeleteModal = (
        <HeaderModal>
            <DeleteHeaderModal>Delete Recourding User</DeleteHeaderModal>
        </HeaderModal>
    );
    return (
        <Modal
            isOpen={deleteModal}
            setIsOpen={setDeleteModal}
            title={TitleDeleteModal}
            Style={{ top: '19%', width: '550px', height: '331px', padding: '20px' }}
            flag={true}
        >
            <BoadyDeleteModal>
                <DeleteCardModal>
                    <TitleDeleteDetails>
                        <ReturnTextDelete>
                            Are you sure you want to delete this recording ?
                        </ReturnTextDelete>
                        <SureButton onClick={() => handleDeleteRecording()}>I’m sure</SureButton>
                    </TitleDeleteDetails>
                </DeleteCardModal>
            </BoadyDeleteModal>
        </Modal>
    );
};
