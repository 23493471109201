import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk } from '../../templates';

interface UsersState {
    entities: [];
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    currentRequestId: undefined;
    error: [];
}

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
} as UsersState;

// Get Video By Id
export const getVedio = (vedioId: Number) => {
    return requestAsyncThunk({
        storeName: 'getVedioDvr',
        _url: `/reviewer/video/view/${vedioId}`,
        method: 'POST',
    });
};

//Get Video from azour api
export const getVedioAzour = (url: string) => {
    return requestAsyncThunk({
        storeName: 'getVedioDvr',
        _url: `${url}`,
        method: 'get',
    });
};
// Create Video Directory Location
export const createDirectoryLocation = (vedioId: Number) => {
    return requestAsyncThunk({
        storeName: 'createDirectoryLocationDvr',
        _url: `/reviewer/feedback/upload/${vedioId}`,
        method: 'POST',
    });
};

// Upload Vedio Video In To Directory Location

export const UploadBirnaryVedio = (payload: any) => {
    return requestAsyncThunk({
        storeName: 'UploadVedioDvr',
        _url: `${payload.url}`,
        method: 'put',
        headers: {
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': 'video/mp4',
        },
        data: payload.data,
    });
};
export const UploadFeedBackVedio = () => {
    return requestAsyncThunk({
        storeName: 'UploadFeedBackDvr',
        _url: `/reviewer/feedback/complete/upload`,
        method: 'POST',
    });
};
export const videoListSlice = createSlice({
    name: 'getVedioDvr',
    initialState,
    reducers: {
        resetAction: () => {
            return initialState;
        },
    },
    // extraReducers: responseAsyncThunk(getVedio(vedioId)),
});
