import React from 'react';
import styled from 'styled-components';

const Body = styled.div<any>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 2;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.2s ease !important;
`;

const Content = styled.div<any>`
    background-color: #fefefe;
    margin: 100px auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    max-width: 80%;
    border-radius: 20px;
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    transition: all 1s ease-in-out !important;
    transition-delay: 0.5s !important;
`;

export const Header = styled.div`
    font-size: 24px;
    font-weight: 700;
    text-align: start;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Amaranth';
    margin-right: 0;
`;

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    font-weight: 400;
    background: #f2f2f290;
    padding: 20px;
    position: relative;
`;

const Title = styled.span`
    margin-inline-end: auto;
`;

const CloseButton = styled.button`
    background: #f2f2f290;
    border: none;
    border: 1px solid #f2f2f290;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 100ms ease;
    &:hover {
        background: #c7c2c290;
        text-decoration: none;
    }
`;

export function Modal({ title, children, isOpen, setOpen, style, ContentStyle }: any) {
    return (
        <Body isOpen={isOpen}>
            <Content isOpen={isOpen} style={{ ...ContentStyle }}>
                <Header>
                    <Title>{title}</Title>
                    <CloseButton onClick={setOpen}>X</CloseButton>
                </Header>
                <ModalBody style={{ ...style }}>{children}</ModalBody>
            </Content>
        </Body>
    );
}
