import React from 'react';
import { ReactComponent as PlaySvg } from 'assets/icons/play.svg';

import { ReactComponent as BackWardSvg } from 'assets/icons/backword.svg';
import { ReactComponent as PauseSvg } from 'assets/icons/pause.svg';

import { ReactComponent as FrowardSvg } from 'assets/icons/froward.svg';

// import { CONTAINER_DYMENTIONS } from 'constants/recorder';
// import { ControlButton } from '../components';
import { ControlsWrap, DurationLabel, Seek, Box, BackwordButton } from './style';
import styled from 'styled-components';
interface controlsProp {
    seekRef: any;
    seek: any;
    time?: string;
    duration: string;
    playing: boolean;
    handleSeekBarChange: any;
    handleBackward: any;
    handlePlayPause: any;
    handleForwad: any;
}
const BackWard = styled(BackWardSvg)`
    width: 1.9vw;
    height: 1.9vw;
    cursor: pointer;
    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        width: 30px;
        height: 30px;
    }

    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */

        width: 25px;
        height: 25px;
    }
    @media screen and (min-width: 767px) and (max-width: 991px) {
        width: 20px;
        height: 20px;
    }
    @media screen and (max-width: 766px) {
        width: 15px;
        height: 15px;
    }
`;
const Pause = styled(PauseSvg)`
    width: 1.5vw;
    height: 1.5vw;
    cursor: pointer;

    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        width: 20px;
        height: 20px;
    }
`;
const Play = styled(PlaySvg)`
    width: 1.5vw;
    height: 1.5vw;
    cursor: pointer;

    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        width: 20px;
        height: 20px;
    }

    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */

        width: 17px;
        height: 17px;
    }
    @media screen and (min-width: 767px) and (max-width: 991px) {
        width: 15px;
        height: 15px;
    }
    @media screen and (max-width: 766px) {
        width: 13px;
        height: 13px;
    }
`;
const Froward = styled(FrowardSvg)`
    width: 1.9vw;
    height: 1.9vw;
    cursor: pointer;

    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        width: 30px;
        height: 30px;
    }

    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */

        width: 25px;
        height: 25px;
    }
    @media screen and (min-width: 767px) and (max-width: 991px) {
        width: 20px;
        height: 20px;
    }
    @media screen and (max-width: 766px) {
        width: 15px;
        height: 15px;
    }
`;

export const Controls = ({
    duration,
    seekRef,
    seek,
    time,
    playing,
    handleSeekBarChange,
    handleBackward,
    handlePlayPause,
    handleForwad,
    ...rest
}: controlsProp & React.HtmlHTMLAttributes<HTMLDivElement>) => {
    return (
        <Box>
            <DurationLabel className="text-truncate">{`${time?.replace(
                ' ',
                '',
            )} / ${duration.replace(' ', '')}`}</DurationLabel>
            <Seek
                ref={seekRef}
                value={seek}
                type={'range'}
                onChange={handleSeekBarChange}
                onClick={handleSeekBarChange}
            />
            <ControlsWrap>
                <BackwordButton>
                    <BackWard onClick={handleBackward} style={{ position: 'absolute' }} />
                </BackwordButton>

                {playing ? <Pause onClick={handlePlayPause} /> : <Play onClick={handlePlayPause} />}
                <BackwordButton>
                    <Froward onClick={handleForwad} style={{ position: 'absolute' }} />
                </BackwordButton>
            </ControlsWrap>
        </Box>
    );
};
