import React, { forwardRef, useRef, useState, useEffect } from 'react';
import { Controls } from './contorls/Controls';
import ClipLoader from 'react-spinners/BeatLoader';
// import { getVideoDurationInSeconds } from 'get-video-duration';
import getBlobDuration from 'get-blob-duration';

import { Spinner } from '../../pages/dvr/style';
interface videoProps {
    style: any;
    playing: any;
    handleSetPlaying: any;
    duration: string;
    src: string;
    showInfoModal?: Boolean;
    returnHomeModal?: Boolean;
    sendVedioModal?: Boolean;
}
export const NativePlayer = forwardRef(
    (
        {
            handleSetPlaying,
            playing,
            style,
            duration,
            showInfoModal,
            returnHomeModal,
            sendVedioModal,
            src,
            ...rest
        }: videoProps & React.VideoHTMLAttributes<HTMLVideoElement>,
        ref: any,
    ) => {
        const seekRef = useRef<any>(null);
        const [seek, setSeek] = useState<number>(0);
        const [BlobDuration, setBlobDuration] = useState<number>(0);

        const [time, setTime] = useState<string>('00:00');
        const [showSpinner, setShowSpinner] = useState(false);
        const getBlobLength = async () => {
            const BlobDuration = await getBlobDuration(src);
            setBlobDuration(BlobDuration);
        };
        useEffect(() => {
            getBlobLength();
        }, []);
        React.useEffect(() => {
            if (showInfoModal === true || returnHomeModal === true || sendVedioModal === true) {
                ref.current?.pause();
                handleSetPlaying(false);
            }
        });
        const timeFormat = (sec: any): string => {
            const minutes: number = Math.floor(sec / 60);
            const seconds: number = Math.floor(sec % 60);

            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        };
        const handleUpdateSeekBar = () => {
            setSeek((100 / BlobDuration) * ref.current.currentTime);
            setTime(timeFormat(ref.current.currentTime));
            let condition = ref.current.currentTime === BlobDuration;

            if (condition) {
                handleSetPlaying(false);
                setSeek(0);
                setTime('00:00');
            }
        };

        const handlePlayPause = () => {
            if (ref.current.paused === true) {
                ref.current.play();
            } else {
                ref.current.pause();
            }

            handleSetPlaying(!playing);
        };

        const handleForwad = () => {
            if (ref.current.currentTime + 2 <= BlobDuration) {
                ref.current.currentTime = ref.current.currentTime + 2;
            } else {
                ref.current.currentTime = BlobDuration;
            }
        };

        const handleBackward = () => {
            if (ref.current.currentTime - 2 >= 0) {
                ref.current.currentTime = ref.current.currentTime - 2;
            } else {
                ref.current.currentTime = 0;
            }
        };

        const handleSeekBarChange = () => {
            let val = 0;
            val = BlobDuration * (seekRef.current.value / 100);
            ref.current.currentTime = val;
        };

        return (
            <>
                {showSpinner && (
                    <Spinner>
                        <ClipLoader size={20} color="#ffff" />
                    </Spinner>
                )}
                <video
                    {...{ ref, ...rest }}
                    src={src}
                    style={{
                        ...style,
                        position: 'absolute',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        background: 'rgb(49, 56, 70) !important',
                    }}
                    onTimeUpdate={handleUpdateSeekBar}
                    onClick={handlePlayPause}
                    onWaiting={() => {
                        setShowSpinner(true);
                    }}
                    onPlaying={() => setShowSpinner(false)}
                    onPause={() => setShowSpinner(false)}
                />
                <Controls
                    playing={playing}
                    duration={duration}
                    seek={seek}
                    time={time}
                    seekRef={seekRef}
                    handleSeekBarChange={handleSeekBarChange}
                    handlePlayPause={handlePlayPause}
                    handleBackward={handleBackward}
                    handleForwad={handleForwad}
                />
            </>
        );
    },
);
