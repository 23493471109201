import styled from 'styled-components';
import { Button } from 'components';
import router from 'routers/router';
import { useLocales } from 'hooks';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'config/azureAuthConfig';
import { ReactComponent as Logo } from 'assets/image/logo.svg';

const BoxForm = styled.div`
    padding-top: 70px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
`;
const LogoBody = styled.div``;

const Form = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    height: auto;
    margin-top: 20px;
    z-index: 1000;
`;

const BoxButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonLogin = styled(Button)`
    margin-top: 30px;
    border-radius: 5px;
    color: #fafbfd;
    height: 41px;
    width: 121px;
    > span {
        font-size: 15px !important;
        font-weight: 400;
    }
`;

const Welcome = styled.span`
    padding: 0;
    font-size: 45px;
    font-family: 'Amaranth';
    margin-top: 20px;
    font-weight: 600;
    color: #0b141d;
`;

const Span = styled.span`
    margin-left: auto;
    margin-right: auto;
    display: flex;
    text-align: center;

    align-items: center;
    text-align: center;
    font-size: 33px;
    font-weight: 300;
    color: #0b141d;
    line-height: 1;
`;

export const Signin = () => {
    const { instance, inProgress } = useMsal();
    const { trans } = useLocales();

    const handleLoginAction = (): void => {
        instance
            .loginPopup(loginRequest)
            .then((result) => {
                router.navigate('home', {}, { replace: true });
            })
            .catch((error) => console.error(error));
    };

    return (
        <BoxForm>
            <Form>
                <LogoBody>
                    <Logo height="150px" width="197px" />
                </LogoBody>
                <Welcome>{trans('signin.welcome')}</Welcome>
                <Span>{trans('signin.login.title')}</Span>
                <BoxButton>
                    <ButtonLogin
                        onClick={handleLoginAction}
                        loading={inProgress === InteractionStatus.Login}
                        disabled={inProgress === InteractionStatus.Login}
                    >
                        <span>{trans('signin.continue')}</span>
                    </ButtonLogin>
                </BoxButton>
            </Form>
        </BoxForm>
    );
};
