import { memo, ReactNode } from 'react';
import styled from 'styled-components';

interface POSLayoutProps {
    children: ReactNode;
}

const Main = styled.main``;

const Content = styled('div')``;

export const POSLayout = memo(({ children }: POSLayoutProps) => {
    return (
        <Main>
            <Content>{children}</Content>
        </Main>
    );
});
