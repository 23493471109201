import { memo, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { Table } from './components';
import { NoData } from './components/noData';
import { dashboardAPI } from 'services/apis';
import { useDispatch, useSelector } from 'react-redux';
import { useLoader, useNotification } from 'hooks';
import { isEmpty } from 'lodash';
import BackgraoundSVG from 'assets/image/new-bg.svg';
import { SUCESS_STATUS } from 'constants/auth';

const Box = styled.div<any>`
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    flex-grow: 1;
    background-image: url(${BackgraoundSVG});
    position: relative;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
`;

const InfiniteScrollStyled = styled<any>(InfiniteScroll)`
    > * {
        margin-inline-end: 25px;
    }
`;

export const Dashboard = memo(() => {
    const { lockLoader } = useLoader();
    const [data, setData] = useState<any>([]);
    const [reviewerName, setReviewerInfo] = useState<string>('');

    const { loading, entities } = useSelector((state: any) => state.videoList);
    const [page, setPage] = useState<any>(0);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(0);

    const dispatch = useDispatch<any>();
    const { error } = useNotification();

    const fetchMoreData = (): any => {
        if (data?.length < totalItems) {
            setPage((e: number) => e + 1);
        } else {
            setHasMore(false);
        }
    };

    const fetchData = async () => {
        const { payload } = await dispatch(dashboardAPI.videoList()({ urlParams: `/${page}` }));

        if (SUCESS_STATUS.includes(payload?.status)) {
            if (!isEmpty(payload.data?.data)) {
                setData((_d: any) => [..._d, ...(payload.data?.data || [])]);
                setHasMore(data?.length < payload.data?.data.length);
                setTotalItems(payload.data?.totalItems);
            }
        } else {
            const message =
                payload?.message?.message || 'Oops there was an error ! Please try again';
            error({
                message: message,
            });
        }
    };

    const handelReturnToAdminVideo = useCallback(
        (val: any) => {
            const newList = data.filter((el: { videoId: any }) => el.videoId !== val);
            setData(newList);
        },
        [data],
    );

    useEffect(() => {
        fetchData();
    }, [page]);

    useEffect(() => {
        if (isEmpty(data)) lockLoader(loading);
    }, [loading]);
    const reviewerInfo = async () => {
        const { payload } = await dispatch(dashboardAPI.getReviewerInfo()({}));
        if (SUCESS_STATUS.includes(payload?.status)) {
            setReviewerInfo(payload?.data?.name);
        }
    };
    useEffect(() => {
        reviewerInfo();
    }, []);
    if (!entities?.data?.length && loading !== 'pending')
        return <NoData reviewerName={reviewerName} />;

    return (
        <Box>
            <Table.Box>
                <Table.Header />
                <Table.Content id="scrollableDiv">
                    <InfiniteScrollStyled
                        style={{
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                        }}
                        dataLength={entities?.totalItems || 0}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<Table.Loader />}
                        scrollableTarget="scrollableDiv"
                    >
                        {data?.map?.((item: any, index: number) => (
                            <Table.Item
                                item={item}
                                key={index}
                                handelReturnToAdminVideo={handelReturnToAdminVideo}
                            />
                        ))}
                    </InfiniteScrollStyled>
                </Table.Content>
            </Table.Box>
        </Box>
    );
});
