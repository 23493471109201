const hasCameraPermissions = async ({ setCamAccess }: any) => {
    try {
        await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        setCamAccess(true);
    } catch {
        setCamAccess(false);
    }
};

export default hasCameraPermissions;
