import * as React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const useVideoJS = (videoJsOptions: any, bearer: any) => {
    const player = React.useRef<any>(null);
    const videoNode = React.useRef<any>(null);
    const setupTokenForDecrypt = (options: any) => {
        if (options.uri.includes('keydeliver')) {
            options.headers = options.headers || {};
            options.headers.Authorization = `Bearer ${bearer}`;
        }
        return options;
    };
    React.useEffect(() => {
        if (!bearer) return;
        player.current = videojs(videoNode.current, videoJsOptions);
        (videojs as any).Vhs.xhr.beforeRequest = setupTokenForDecrypt;
    }, [bearer]);

    const Video = React.useCallback(({ children, handleUpdateSeekBar, ...props }: any) => {
        return (
            <>
                <div data-vjs-player>
                    <video
                        ref={videoNode}
                        className="video-js"
                        {...props}
                        id="video-player"
                        onTimeUpdate={handleUpdateSeekBar}
                        crossOrigin="anonymouse"
                    >
                        {children}
                    </video>
                </div>
            </>
        );
    }, []);
    return { Video, player: player.current };
};
