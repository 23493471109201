const authRequest = (store: any) => {
    return [
        (config: any) => {
            return config;
        },
        (error: any) => {
            return Promise.reject(error.data.error.message);
        },
    ];
};

export default authRequest;
