import { Modal } from 'components';

import {
    HeaderModal,
    HeaderTitle,
    InfoModalMody,
    UserInfoDetails,
    CardModal,
    TitleDetails,
    TitleLabel,
    TitleVlaue,
    LastFeedbakDetails,
} from '../style';

export const ShowInfoModal = ({ showInfoModal, setInfoShowModal, itemDetalis }: any) => {
    const TitleInfoModal = (
        <HeaderModal>
            <HeaderTitle>User details</HeaderTitle>
            <HeaderTitle last={true}>Last feedbak details</HeaderTitle>
        </HeaderModal>
    );
    return (
        <Modal
            isOpen={showInfoModal}
            setIsOpen={setInfoShowModal}
            title={TitleInfoModal}
            infoModal={true}
            Style={{ top: '2%' }}
            flag={false}
        >
            <InfoModalMody>
                <UserInfoDetails>
                    <CardModal>
                        <TitleDetails>
                            <TitleLabel>Child name </TitleLabel>
                            <TitleVlaue> {itemDetalis?.childName || ' - '}</TitleVlaue>
                        </TitleDetails>
                        <TitleDetails>
                            <TitleLabel>Child age </TitleLabel>
                            <TitleVlaue>{`${itemDetalis?.dateOfBirthYears || ' - '}Y ${
                                itemDetalis?.dateOfBirthMonths || ' - '
                            }M`}</TitleVlaue>
                        </TitleDetails>
                        <TitleDetails>
                            <TitleLabel>Adult Name </TitleLabel>
                            <TitleVlaue>{itemDetalis?.name || '-'} </TitleVlaue>
                        </TitleDetails>
                    </CardModal>
                    <CardModal>
                        <TitleDetails>
                            <TitleLabel>Uploaded on </TitleLabel>
                            <TitleVlaue> {itemDetalis?.timeUploaded || '-'}</TitleVlaue>
                        </TitleDetails>
                        <TitleDetails>
                            <TitleLabel>Duration </TitleLabel>
                            <TitleVlaue> {itemDetalis?.videoLength || '-'}</TitleVlaue>
                        </TitleDetails>
                        <TitleDetails>
                            <TitleLabel>Module name </TitleLabel>
                            <TitleVlaue>{itemDetalis?.module_name || '-'} </TitleVlaue>
                        </TitleDetails>
                    </CardModal>
                    <CardModal>
                        <TitleDetails>
                            <TitleLabel>Notes </TitleLabel>
                            <TitleVlaue>{itemDetalis?.uploadComment || '-'}</TitleVlaue>
                        </TitleDetails>
                    </CardModal>
                </UserInfoDetails>
                <LastFeedbakDetails>
                    <CardModal>
                        <TitleDetails>
                            <TitleLabel>Reviewer name</TitleLabel>
                            <TitleVlaue> {itemDetalis?.feedback?.name || '-'}</TitleVlaue>
                        </TitleDetails>
                        <TitleDetails>
                            <TitleLabel>Practiced Module</TitleLabel>
                            <TitleVlaue> {itemDetalis?.feedback?.module_name || '-'}</TitleVlaue>
                        </TitleDetails>
                    </CardModal>
                    <CardModal>
                        <TitleDetails>
                            <TitleLabel>Notes</TitleLabel>
                            <TitleVlaue>{itemDetalis?.feedback?.adminSummary || '-'}</TitleVlaue>
                        </TitleDetails>
                    </CardModal>
                </LastFeedbakDetails>
            </InfoModalMody>
        </Modal>
    );
};
