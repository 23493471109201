import { memo, ReactNode } from 'react';
import styled from 'styled-components';

import { Header } from '../../layout/components';
import Logo from 'assets/image/logo.svg';
import Background from 'assets/image/background-all-level.svg';
import BackgraoundSVG from 'assets/image/new-bg.svg';

interface DashboardLayoutProps {
    children: ReactNode;
}

const Main = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    background: #ffffff;
    /* background-image: url(${BackgraoundSVG});
    position: relative;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat; */
    > div {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const ImageBox = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    top: 11%;
`;

const BackgroundBox = styled.img`
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 10%;
`;

const Content = styled('div')`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
`;

export const DashboardLayout = memo(({ children }: DashboardLayoutProps) => {
    return (
        <Main>
            <Header Logo={Logo} colorBackground="#fafbfd" />
            <Content>{children}</Content>
            <ImageBox>
                <BackgroundBox src={Background} />
            </ImageBox>
        </Main>
    );
});
