import React, { useState, useEffect } from 'react';
import { useRecorder, useNotification, useLoader, useLocales } from 'hooks';
import { Player, NativePlayer } from 'components';
import { useDispatch } from 'react-redux';
import { SUCESS_STATUS } from 'constants/auth';
import { vedioAPI, dashboardAPI } from 'services/apis';
import { CONTAINER_DYMENTIONS } from 'constants/recorder';
import router from 'routers/router';
import getBlobDuration from 'get-blob-duration';
import { useStopwatch } from 'react-timer-hook';
import { SendVideoModal, ReturnToAdminModal, DeleteModal, ShowInfoModal } from './modals';
import { Footer } from '../video/components';
import axios from 'axios';

import {
    Box,
    ControlsBox,
    ControlsButton,
    ControlsLeftSide,
    HiddenCanvas,
    RepalyBox,
    VedioBox,
    VideoLayout,
    BlockPermission,
    BlockPermissionSpan,
    VedioCamera,
    NoData,
    BlinkRecourding,
    TimerSpan,
    TimerBox,
    NotBlinkRecourding,
} from './style';
import useRecorderPermission from './useRecorderPermission';
interface DvrProps {
    vedioId: Number;
    setInfoShowModal: Function;
    showInfoModal: boolean;
    returnHomeModal: boolean;
    hideInfoIcon: Function;
}
export const Dvr = ({
    vedioId,
    setInfoShowModal,
    showInfoModal,
    returnHomeModal,
    hideInfoIcon,
}: DvrProps) => {
    const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
        autoStart: false,
    });

    const { lockLoader } = useLoader();
    const { trans } = useLocales();
    const [outputSrc, setOutputSrc] = useState<string>('');
    const [videoURl, setVideoURl] = useState<string>('');
    const [bearer, setBearer] = useState<string>('');
    const [videoError, setGetVideoError] = useState<string>('');
    const [feedback, setFeedback] = useState<string>('');
    const [itemDetalis, setItemDetails] = useState<any>({});
    const [blobState, setBlob] = useState<any>();
    const [loadingUpload, setLoadingUpload] = useState<Boolean>(false);
    const [returnToAdminModal, setReturnToAdminModal] = useState<Boolean>(false);
    const [deleteModal, setDeleteModal] = useState<Boolean>(false);

    const [showReplay, setShowReplay] = useState<Boolean>(false);

    const [deleteButton, setDeleteButton] = useState<Boolean>(false);
    const [isRecording, setisRecording] = useState<Boolean>(false);
    const [showRecourding, setShowRecourding] = useState<Boolean>(false);
    const [HideRecourding, setHideRecourding] = useState<Boolean>(false);

    const [isPaused, setIsPaused] = useState<Boolean>(false);

    const [playing, setPlaying] = useState<Boolean>(false);
    const [DisabledSend, setDisabledSend] = useState<Boolean>(true);
    const { hasPermission } = useRecorderPermission();
    const [returnToAdminLoading, setReturnToAdminLoading] = useState(false);
    const { success, error } = useNotification();

    const [duration, setDuration] = useState<string>('00:00');

    const [sendVedioModal, setSendVidoeModal] = useState<Boolean>(false);
    const {
        outputVideoRef,
        videoRef,
        cameraRef,
        canvasRef,
        setReady,
        recorderController,
        isReady,
    } = useRecorder();
    const dispatch = useDispatch<any>();
    const timeFormat = (sec: any): string => {
        const minutes: number = Math.floor(sec / 60);
        const seconds: number = Math.floor(sec % 60);

        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    useEffect(() => {
        if (!videoRef) return;
        const interval = setInterval(async () => {
            if (!showReplay || !videoRef?.current?.duration) {
                setDuration(timeFormat(videoRef?.current?.duration));
            }
        });

        return () => clearInterval(interval);
    });
    const handleStartRecording = () => {
        recorderController?.startRecording();
        setisRecording(true);
        setShowRecourding(true);
        hideInfoIcon(true);
        setHideRecourding(true);
        start();
    };

    const handleStopRecording = async () => {
        await recorderController?.stopRecording?.();
        const blob = await recorderController?.getBlob?.();
        if (blob) {
            setBlob(blob);
            setOutputSrc(URL.createObjectURL?.(blob));
            setisRecording(false);
            setIsPaused(false);
            setPlaying(false);
            setDisabledSend(false);
            setShowRecourding(false);
            setDeleteButton(true);
            pause();
        }

        if (!videoRef) {
            return null;
        } else {
            videoRef.current.currentTime = 0;
            videoRef.current.pause();
        }
    };
    const handlePauseRecording = async () => {
        await recorderController?.pauseRecording?.();
        setIsPaused(true);
        setShowRecourding(false);
        hideInfoIcon(false);
        pause();
    };

    const handleResumeRecording = async () => {
        await recorderController?.resumeRecording?.();
        setIsPaused(false);
        setShowRecourding(true);
        hideInfoIcon(true);
        start();
    };
    const handleDeleteRecording = () => {
        setShowReplay(false);
        setisRecording(false);
        setIsPaused(false);
        setOutputSrc('');
        setDisabledSend(true);
        setShowRecourding(false);
        hideInfoIcon(false);
        setHideRecourding(false);
        setDeleteButton(false);
        reset();
        setDeleteModal(false);
    };
    const handleSetPlaying = (isPlaying: Boolean) => {
        setPlaying(isPlaying);
    };
    const handleWatchRecording = async () => {
        setShowReplay(true);
        setShowRecourding(false);
        hideInfoIcon(false);
        handleSetPlaying(false);

        videoRef.current?.pause();

        outputVideoRef?.current?.pause();
        const blobUrl = outputSrc;
        const BlobDuration = await getBlobDuration(blobUrl);
        setDuration(timeFormat(BlobDuration));
    };
    const handleWatchOriginal = async () => {
        setShowReplay(false);
        handleSetPlaying(false);
        videoRef.current?.pause();

        outputVideoRef?.current.pause();
    };

    const getVedio = async () => {
        let { payload } = await dispatch(vedioAPI.getVedio(vedioId)({}));
        if (SUCESS_STATUS.includes(payload?.status)) {
            setVideoURl(payload?.data?.url);
            setBearer(payload?.data?.token);
        } else {
            setGetVideoError(payload?.message?.message);
        }
    };
    const getVideoDetails = async () => {
        const { payload } = await dispatch(
            dashboardAPI.videoDetails()({ urlParams: `/${vedioId}` }),
        );
        setItemDetails(payload.data);
    };
    useEffect(() => {
        getVedio();
        getVideoDetails();
    }, [vedioId]);
    const SendVideo = async () => {
        setLoadingUpload(true);
        const { payload } = await dispatch(vedioAPI.createDirectoryLocation(vedioId)({}));
        if (SUCESS_STATUS.includes(payload?.status)) {
            const file = new File([blobState], String(new Date()), {
                type: 'video/mp4',
            });
            let config = {
                method: 'put',
                url: payload?.data?.feedbackUploadLinkUrl,
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': 'video/mp4',
                },
                data: file,
            };
            await axios(config)
                .then(async (response) => {
                    if (SUCESS_STATUS.includes(response?.status)) {
                        const Data = {
                            videoId: vedioId,
                            assetName: payload?.data?.assetName,
                            adminSummary: feedback,
                        };
                        if (
                            SUCESS_STATUS.includes(payload?.status) &&
                            SUCESS_STATUS.includes(response?.status)
                        ) {
                            await dispatch(vedioAPI.UploadFeedBackVedio()(Data));
                            setFeedback('');
                            setSendVidoeModal(false);
                            setLoadingUpload(false);
                            success({
                                message: 'Success',
                            });
                            router.navigate('home', {}, { replace: true });
                        }
                    } else {
                        setLoadingUpload(false);
                        const message = payload?.message?.message;

                        error({
                            message: message,
                        });
                    }
                })
                .catch(function (error) {
                    setLoadingUpload(false);
                });
        } else {
            setLoadingUpload(false);
            error({
                message: payload?.message?.message,
            });
        }
    };

    const handelToVideoAdmin = () => {
        setReturnToAdminLoading(true);
        dispatch(dashboardAPI.linkToAdmin()({ urlParams: `/${vedioId}` })).then(
            ({ payload }: { payload: any }) => {
                if (SUCESS_STATUS.includes(payload?.status)) {
                    success({
                        message: 'Success',
                    });
                    router.navigate('home', {}, { replace: true });
                } else {
                    const message =
                        payload?.message?.message || 'Oops there was an error ! Please try again';
                    error({
                        message: message,
                    });
                }

                setReturnToAdminLoading(false);
            },
        );
    };

    const onUnload = (e: any) => {
        // the method that will be used for both add and remove event
        e.preventDefault();
        e.returnValue = 'ascascaxasx';
    };
    useEffect(() => {
        window.addEventListener('beforeunload', onUnload);
        return () => window.removeEventListener('beforeunload', onUnload);
    }, []);

    useEffect(() => {
        lockLoader(isReady ? 'idle' : 'pending');
    }, [isReady]);
    return (
        <>
            <Box>
                {!hasPermission && isReady && (
                    <BlockPermission>
                        <BlockPermissionSpan>
                            You Have No Permisson Mic And Camera ...
                            <br></br>
                            Please Give The Permission
                        </BlockPermissionSpan>
                    </BlockPermission>
                )}
                <VedioBox>
                    {outputSrc !== '' && showReplay && (
                        <RepalyBox showReplay={showReplay}>
                            <VideoLayout>
                                <NativePlayer
                                    ref={outputVideoRef}
                                    src={outputSrc}
                                    controls={false}
                                    style={{
                                        width: CONTAINER_DYMENTIONS.width,
                                        height: CONTAINER_DYMENTIONS.height,
                                    }}
                                    crossOrigin="anonymouse"
                                    playing={playing}
                                    handleSetPlaying={handleSetPlaying}
                                    duration={duration}
                                    onWaiting={() => {}}
                                    showInfoModal={showInfoModal}
                                    returnHomeModal={returnHomeModal}
                                />
                            </VideoLayout>
                        </RepalyBox>
                    )}
                    <RepalyBox showReplay={!showReplay}>
                        <VideoLayout>
                            {bearer ? (
                                <React.Fragment>
                                    <Player
                                        ref={videoRef}
                                        src={videoURl}
                                        bearer={bearer}
                                        setReady={setReady}
                                        style={{
                                            width: CONTAINER_DYMENTIONS.width,
                                            height: CONTAINER_DYMENTIONS.height,
                                        }}
                                        crossOrigin="anonymouse"
                                        playing={playing}
                                        handleSetPlaying={handleSetPlaying}
                                        duration={duration}
                                        showInfoModal={showInfoModal}
                                        returnHomeModal={returnHomeModal}
                                        sendVedioModal={sendVedioModal}
                                    />
                                </React.Fragment>
                            ) : (
                                <NoData>
                                    {videoError && (
                                        <React.Fragment>
                                            <span>{videoError} </span>

                                            <span>X</span>
                                        </React.Fragment>
                                    )}
                                </NoData>
                            )}
                            <VedioCamera loop ref={cameraRef} autoPlay muted />
                        </VideoLayout>
                    </RepalyBox>
                </VedioBox>

                <HiddenCanvas ref={canvasRef} />
            </Box>
            <ControlsBox>
                <ControlsLeftSide left={true}>
                    {outputSrc.length > 0 && !showReplay ? (
                        <ControlsButton
                            onClick={handleWatchRecording}
                            disabled={returnHomeModal || videoError.length > 0}
                        >
                            <span className="text-truncate">{trans('dvr.watch.recording')}</span>
                        </ControlsButton>
                    ) : (
                        outputSrc.length > 0 && (
                            <ControlsButton
                                onClick={handleWatchOriginal}
                                disabled={returnHomeModal || videoError.length > 0}
                            >
                                <span className="text-truncate">{trans('dvr.watch.original')}</span>
                            </ControlsButton>
                        )
                    )}
                    {!isRecording ? (
                        outputSrc.length <= 0 && (
                            <ControlsButton
                                onClick={handleStartRecording}
                                disabled={returnHomeModal || videoError.length > 0}
                            >
                                <span className="text-truncate">
                                    {trans('dvr.start.recording')}
                                </span>
                            </ControlsButton>
                        )
                    ) : (
                        <>
                            {!isPaused ? (
                                <ControlsButton
                                    onClick={handlePauseRecording}
                                    disabled={returnHomeModal || videoError.length > 0}
                                >
                                    <span className="text-truncate">
                                        {' '}
                                        {trans('dvr.pause.recording')}
                                    </span>
                                </ControlsButton>
                            ) : (
                                <ControlsButton
                                    onClick={handleResumeRecording}
                                    disabled={returnHomeModal || videoError.length > 0}
                                >
                                    <span className="text-truncate">
                                        {trans('dvr.resume.recording')}
                                    </span>
                                </ControlsButton>
                            )}
                        </>
                    )}
                    <TimerBox>
                        {HideRecourding && (
                            <>
                                <TimerSpan>
                                    {hours ? (hours < 10 ? `0${hours}` : hours) : '00'} :
                                    {minutes ? (minutes < 10 ? `0${minutes}` : minutes) : '00'} :{' '}
                                    {seconds ? (seconds < 10 ? `0${seconds}` : seconds) : '00'}
                                </TimerSpan>
                                {showRecourding ? (
                                    <BlinkRecourding>
                                        <span></span>
                                    </BlinkRecourding>
                                ) : (
                                    <NotBlinkRecourding>
                                        <span></span>
                                    </NotBlinkRecourding>
                                )}
                            </>
                        )}
                    </TimerBox>
                </ControlsLeftSide>
                <ControlsLeftSide>
                    <ControlsButton
                        onClick={() => setDeleteModal(true)}
                        disabled={returnHomeModal || videoError.length > 0}
                        style={deleteButton ? { visibility: 'visible' } : { visibility: 'hidden' }}
                    >
                        <span className="text-truncate">{trans('dvr.delete.recording')}</span>
                    </ControlsButton>
                    {isRecording && (
                        <ControlsButton
                            onClick={handleStopRecording}
                            disabled={returnHomeModal || videoError.length > 0}
                        >
                            <span className="text-truncate">{trans('dvr.complete.recording')}</span>
                        </ControlsButton>
                    )}
                </ControlsLeftSide>
            </ControlsBox>
            <SendVideoModal
                sendVedioModal={sendVedioModal}
                setSendVidoeModal={setSendVidoeModal}
                setFeedback={setFeedback}
                feedback={feedback}
                SendVideo={SendVideo}
                loadingUpload={loadingUpload}
                moduleName={itemDetalis?.module_name}
            />
            <ReturnToAdminModal
                handelToVideoAdmin={handelToVideoAdmin}
                returnToAdminLoading={returnToAdminLoading}
                returnToAdminModal={returnToAdminModal}
                setReturnToAdminModal={setReturnToAdminModal}
            />
            <DeleteModal
                deleteModal={deleteModal}
                handleDeleteRecording={handleDeleteRecording}
                setDeleteModal={setDeleteModal}
            />
            <ShowInfoModal
                showInfoModal={showInfoModal}
                setInfoShowModal={setInfoShowModal}
                itemDetalis={itemDetalis}
            />

            <Footer
                disabled={DisabledSend}
                SendVideo={() => setSendVidoeModal(true)}
                returnToAdmin={() => setReturnToAdminModal(true)}
                returnToAdminLoading={returnToAdminLoading}
            />
        </>
    );
};
