import styled from 'styled-components';

const DefaultBox = styled.div`
    display: flex;
    flex-direction: column;
    background: transparent;
    overflow: auto;
    height: 100%;
    flex-grow: 1;
    margin-bottom: 100px;
`;

export function Content({ children, ...rest }: any) {
    return <DefaultBox {...rest}>{children}</DefaultBox>;
}
