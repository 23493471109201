const hasMicrophonePermissions = async ({ setMicAccess }: any) => {
    try {
        await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        setMicAccess(true);
    } catch {
        setMicAccess(true);
    }
};

export default hasMicrophonePermissions;
