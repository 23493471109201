import { get } from 'lodash';
import { PUBLIC_ROUTES } from 'constants/auth';
import { persistor } from 'app/store';
import { msalInstance } from '../../index';
import { loginRequest } from 'config/azureAuthConfig';

const authenticationMiddleware = () => (toState: any, fromState: any, done: any) => {
    const authAction = async () => {
        const nextState = get(toState, 'name', null);

        const isPublicPage = PUBLIC_ROUTES.includes(nextState);
        let hasAccessToken = false;

        const account = msalInstance.getActiveAccount();

        if (!account) {
            hasAccessToken = false;
        } else {
            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account,
            });
            hasAccessToken = !!response.accessToken;
        }

        if (!isPublicPage && hasAccessToken) {
            done();
            return;
        }

        if (hasAccessToken) {
            if (nextState === 'home') {
                done();
                return;
            }

            done({
                redirect: {
                    name: 'home',
                    path: '/',
                    meta: { options: { replace: true }, redirected: true },
                },
            });
            return;
        }

        if (nextState === 'signin') {
            done();
            return;
        }

        done({
            redirect: {
                name: 'signin',
                path: '/signin',
                meta: { options: { replace: true }, redirected: true },
            },
        });

        return;
    };

    if (persistor.getState().bootstrapped) authAction();
    persistor.subscribe(authAction);
};

export default authenticationMiddleware;
