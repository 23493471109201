import { memo, ReactNode } from 'react';
import BackgroundSVG from 'assets/image/socialmind-bg.svg';
import styled from 'styled-components';

interface AuthLayoutProps {
    children: ReactNode;
}

const Main = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 360px;
`;

const Content = styled('div')`
    z-index: 1;
    width: 100%;
    flex-grow: 1;
    display: flex;
    overflow-y: auto;
    background-image: url(${BackgroundSVG});
    background-position: bottom;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
`;

export const AuthLayout = memo(({ children }: AuthLayoutProps) => {
    return (
        <Main>
            <Content>{children}</Content>
        </Main>
    );
});
